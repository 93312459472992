import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Standing from '../Components/Standing';
import Loading from '../Components/Loading';
import { useNavigate  } from "react-router-dom";
import SEO from '../Components/Seo';

function Standings(){

    const navigate = useNavigate();
    //const [standings, setStandings] = useState([]);
    const [content, setContent] = useState();

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {

        setContent(<Loading text="Loading picks" />);

        fetch("standings/full", {
            method: 'GET'})
            .then((response) => {

                if (response.status === 401) {
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                //setStandings(data);
                setContent(<Standing standingItems={data} />  );
                
            })
            .catch(() => {

            })
            .finally(() => {

            });
    }

    return (
        <>
            <SEO 
                title="FantasyBlizzard.com -- Full Standings"
                description="Full standings for the current Fantasy Blizzard season"
                crawl={false} 
            />
            <h1>Standings</h1>
            <Row>
                <Col sm="12">
                    <p>Listed below are the full standings for the current Fantasy Blizzard season, broken into two sections; those pacing above the minimum requirement and those falling behind.</p>
                    <p>For Divisional standings, please <Link className="text-decoration-none" to="/divisional-standings">click here</Link>.</p>
                </Col>
            </Row>
            <Card>
                <Card.Header className="bg-dark text-white">Full Standings</Card.Header>
                <Card.Body>
                    {content}                    
                </Card.Body>
            </Card>
        </>
    );
}
export default Standings;