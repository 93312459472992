import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend);

function PlayerPicksDistribution(props) {

    const constColors = [
        {background: "rgba(255,0,0, .75)"}, // red
        {background: "rgba(255,165,0, .75)"}, // orange
        {background: "rgba(255, 206, 86, .75"}, // yellow
        {background: "rgba(0, 0, 235, .75)"}, // purple 
        {background: "rgba(0,128,0, .75)"}, // green        
        {background: "rgba(153, 102, 255, .75)"}, // blue
        {background: "rgba(0,0,128, .75"}, // dark blue
        {background: "rgba(238,130,238, .75"}, // violet
        {background: "rgba(192,192,192, .75"}, // silver
        {background: "rgba(0,0,0, .75"}, // black        
    ];

    const options = {        
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
              position: 'bottom',
            },
            datalabels: {
              display: true,
              color: 'white',
              formatter: function(value) {
                return value + " " + (value === 1 ? "pick" : "picks");
              }
           }        
        },
        tooltip: {
            callbacks: {
              label: (context) => {
                let label = "";
                if (context.parsed.y) {
                  label = context.parsed.y + "%"
                }
                return label;
              }
            }
          }
     };

    const data = {
        labels: props.labels,
        datasets: [
          {
            label: props.labelText,
            data: props.data,
            backgroundColor: [
                constColors[0].background,
                constColors[1].background,
                constColors[2].background,
                constColors[3].background,
                constColors[4].background,
                constColors[5].background,
                constColors[6].background,
                constColors[7].background,
                constColors[8].background,
                constColors[9].background    
            ],
            borderWidth: 0,
          },
        ],
      };
      

  return <Pie data={data} options={options} />;
}

export default PlayerPicksDistribution;
