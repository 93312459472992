import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate  } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react';
import SEO from '../Components/Seo';

function AdminUsers(){

    const [users, setUsers] = useState([]);
    const [resetPasswordError, setResetPasswordError] = useState("");
    const [sendingForm, setSendingForm] = useState(false);  
    const [resetSuccess, setResetSuccess] = useState(false);  
    
    const userRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
    }, []);

    function fetchUsers(){
        fetch("/adminUtil/users/active")
        .then((response) => {
            
            if(response.status !== 200){
                navigate("/login");
            } 

            return response.json();
        })
        .then((response) => {
            if(response.success){
                setUsers(response.data);
            }
        })
        .catch(() => {
            
        })
        .finally(() => {

        });
    }

    function resetPasswordHandler(e){

        e.preventDefault();

        setResetSuccess(false);
        
        let errors = "";

        if(userRef.current.value !== "0")
        {
            setSendingForm(true);

            var body = {
                username: userRef.current.value
            }

            fetch("/adminUtil/user/reset-password", {
                method: "POST", 
                headers: {
                "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            })
            .then((response) => {

                if(response.status !== 200){
                    navigate("/login");
                }  

                return response.json();
            })
            .then((response) => {    
                if(response.success){
                    setResetSuccess(true);
                    userRef.current.value = "0";
                }
                else{
                    setResetPasswordError(response.data);
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setSendingForm(false);
            });
        }
        else{
            errors = "Please select a user";            
        }

        setResetPasswordError(errors);
    }

    return (
        <>
        <SEO 
            title="FantasyBlizzard.com -- Manage Users" 
            description="" 
            crawl={false} 
        />
        <Row>
            <Col>
                <h1>Manage Users</h1>
            </Col>
        </Row>
        <Row>
            <Col>            
                <Card>
                    <Card.Header className="bg-dark text-white">Reset Password</Card.Header>
                        <Card.Body>
                            <Form onSubmit={resetPasswordHandler}>
                                <Form.Group className="mb-3">
                                    <Form.Label>User</Form.Label>                                    
                                    <Form.Select ref={userRef}>
                                        <option key="0" value="0">Select</option>
                                        {users.map((x, index) => (
                                            <option key={index} value={x.id}>{x.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Button variant="primary" type="submit">Reset Password</Button>
                                { sendingForm && <Spinner className='login-loading' animation="border" />}  
                                {resetPasswordError.length > 0 && <Row className="mt-3">
                                    <Col>
                                        <Alert key="1" variant="danger">
                                            <strong>{resetPasswordError}</strong>
                                        </Alert>
                                    </Col>
                                </Row> }
                                {resetSuccess && <Row className="mt-3">
                                    <Col>
                                        <Alert key="1" variant="success">
                                            <strong>Password was reset</strong>
                                        </Alert>
                                    </Col>
                                </Row> }
                            </Form>
                        </Card.Body>
                    </Card>
            </Col>
        </Row>
        </>

    );
}

export default AdminUsers;