import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import Loading from '../Components/Loading'
import LoadingError from '../Components/LoadingError'
import { getStandingsRowClass } from '../Helper'
import ProfileLink from './ProfileLink';

function TopStandings(){

    const [top10Content, setTop10Content] = useState();

    useEffect(() => {

        setTop10Content(<Loading text="Loading standings. Sit tight" />);

        fetch("standings/top")
            .then((response) => {

                if (!response.ok) {
                    setTop10Content(<LoadingError />);
                }
                return response.json();
            })
            .then((data) => {
                buildStandings(data);
            })
            .catch(() => {
                setTop10Content(<LoadingError />);
            })
            .finally(() => {

            });
    }, []);   

    const buildStandings = function (data) {

        let standingsContent = [];
        const standings = [];
        const standingItems = data.length > 10 ? data.slice(0, 10) : data;

        if(standingItems.length > 0){   
            standingItems.forEach(function (item) {
                standings.push({ rank: item.rank, name: item.displayName, points: item.points.toFixed(2), picks: item.picks, average: item.average.toFixed(2), isOnPace: item.isOnPace, isOnBubble: item.isOnBubble });
            })

            standingsContent = standings.map(x => (<tr key={x.rank} className={getStandingsRowClass(x)}>
                <td className="text-center">{x.rank}</td>
                <td title={x.name}>
                    <ProfileLink name={x.name} maxLength="20" />
                </td>
                {/* <td className="text-center">{x.points}</td> */}
                <td className="text-center">{x.picks}</td>
                <td className="text-center">{x.average}</td>
            </tr>))
        }
        else{
            standingsContent = [
                <tr key="1">
                    <td colSpan={4}>Standings are not available right now</td>
                </tr>];
        }

        const fullStandings = [<Table key="1" bordered hover size="sm" className="mb-0" responsive>
            <thead>
                <tr className="table-secondary">
                    <th className="text-center"></th>
                    <th>Name</th>
                    {/* <th className="text-center">Points</th> */}
                    <th className="text-center">Picks</th>
                    <th className="text-center">Average</th>
                </tr>
            </thead>
            <tbody>
                {standingsContent}
            </tbody>
        </Table >];  

        setTop10Content(fullStandings);
    }
    
    return (top10Content);
}

export default TopStandings;