import React, {useEffect, useState} from 'react';
import {Card, Spinner} from 'react-bootstrap'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function LocationLiveScoreLineGraph(props) {

    const [data, setData] = useState({});
    //const [rawData, setRawData] = useState({});
    const [loadingComplete, setLoadingComplete] = useState(false);

    useEffect(() => {        
        fetchData();       
    }, []);

    function fetchData(){
    
        fetch("/scores/live/hourly/location/" + props.locationID)
          .then((response) => {    
            if(response.ok)
            {        
              return response.json();
            }
          })
          .then((response) => {

            if(response.success){ 
              setupChartDataObject(response.data);
            }            
          })
          .catch(() => {
          })
          .finally(() => {
            setLoadingComplete(true);
          });
    }

    function setupChartDataObject(hoursData){
      
        const labels = ['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm'];

        const chartData = {
            labels,
            datasets: [
              {
                label: 'Total Score',
                data: hoursData.map(x => x.score),
                backgroundColor: 'rgba(255, 99, 132, 0.5)'},
              {
                label: 'Snow Points',
                data: hoursData.map(x => x.snow),
                backgroundColor: 'rgb(192,192,192)'},
              {
                label: 'Wind Points',
                data: hoursData.map(x => x.wind),
                backgroundColor: 'rgb(75, 192, 192)'},
              {
                label: 'HDD Points',
                data: hoursData.map(x => x.hdd),
                backgroundColor: 'rgb(0,0,255)'
              }
            ]
        };

        //setRawData(hoursData);
        setData(chartData);
        setLoadingComplete(true);
    }
      
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',

          },
          title: {
            display: false,
            text: 'Chart.js Line Chart',
          },
        },
        scales: {
            y: {
                min: 0
            }
        }
    };

  return (
    <Card style={{ height: '420px' }}>
    <Card.Header className="bg-dark text-white">Today&apos;s Score By Hour</Card.Header>
    <Card.Body>
        {!loadingComplete && <Spinner className="card-body-loading" animation="border" />}
        {loadingComplete && <><Line options={options} data={data} /> <span className="all-times-text">All times local</span></> }                 
    </Card.Body>
    </Card>
  );   
}

export default LocationLiveScoreLineGraph;
