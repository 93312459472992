import React, { useEffect } from "react";
import { GoogleMap, Marker, InfoWindow, LoadScript} from "@react-google-maps/api";

const { useState } = React;
const containerStyle = {
    width: "100%",
    height: "100%"
};

const center = { lat: 40.0, lng: -95.00 };

function PicksMap(props) {

    const [markers, setMarkers] = useState([]);
    const [infoWindowPosition, setInfoWindowPosition] = useState({ lat: 30.00, lng: -95.00 });
    const [showInfoWindow, setShowInfoWindow] = useState({ visible: false, name: "", userPicks: [] });

    useEffect(() => {

        const url = "maps/getMarkers" + (Object.hasOwn(props, "date") ? "/" + props.date : "");

         fetch(url)
            .then((response) => {   
                return response.json();
            })
            .then((data) => {
                setupMap(data);
            })
            .catch(() => {
                
            })
            .finally(() => {

            });
    }, [props.date]);   

    const setupMap = function (data) {
        
        if (data !== null) {
            const markersList = data.map((location, i) => {
                const marker = { lat: location.latitude, lng: location.longitude };
                const index = i + 1;
                   
                return (
                    <Marker
                        key={index}
                        position={marker}                        
                        onClick={() => {      
                            setShowInfoWindow({ visible: true, name: location.name, userPicks: location.userPicks });
                            setInfoWindowPosition(marker);
                        }} 
                        
                        icon={{
                            url: (require(location.userPicks.length > 0 ? '../Assets/Images/has_picks_marker.png' : '../Assets/Images/blue-dot.png')),
                          scale: 1
                        }}
                    />
                );
            });
            setMarkers(markersList)
        }
    }

    const windowCloseHandler = function () {
        setShowInfoWindow({ visible: false, name: "", userPicks: [] });
    }

    return (
        <LoadScript googleMapsApiKey="AIzaSyBP_J5w_dEq0pxQTJuI0apXs9d2v8S6rvk">
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={4}>
                {markers}
                {showInfoWindow.visible && <InfoWindow key="1" position={infoWindowPosition} onCloseClick={windowCloseHandler} >
                    <>
                        <div className="map-location-name mb-1">{showInfoWindow.name}</div>
                        <div className="map-user-picks">
                            {showInfoWindow.userPicks.map((x, index) => <span key={index}>{ x + (index < showInfoWindow.userPicks.length - 1 ? ", " : "")}</span>) }
                        </div>
                    </>
                </InfoWindow>}
            </GoogleMap>
        </LoadScript>
    );
}

export default PicksMap;
