import React, { useEffect } from "react";
import {
    GoogleMap,
    Marker,
    InfoWindow,
    LoadScript
} from "@react-google-maps/api";
import {Button} from 'react-bootstrap';

const { useState } = React;
const containerStyle = {
    width: "100%",
    height: "100%"
};

const center = { lat: 40.0, lng: -95.00 };

function MyPicksMap(props) {

    const [markers, setMarkers] = useState([]);
    const [infoWindowPosition, setInfoWindowPosition] = useState({ lat: 30.00, lng: -95.00 });
    const [showInfoWindow, setShowInfoWindow] = useState({ visible: false, name: "", id: 0 });

    useEffect(() => {

         fetch("maps/getMarkers")
            .then((response) => {   
                return response.json();
            })
            .then((data) => {
                setupMap(data);
            })
            .catch(() => {
                
            })
            .finally(() => {

            });
    }, []);   

    const selectionHandler = function (){
        props.selectionHandler(showInfoWindow);
        windowCloseHandler();
    }

    const setupMap = function (data) {
        
        if (data !== null) {
            const markersList = data.map((location, i) => {
                const marker = { lat: location.latitude, lng: location.longitude };
                const index = i + 1;
                   
                return (
                    <Marker
                        key={index}
                        position={marker}                        
                        onClick={() => {      
                            setShowInfoWindow({ visible: true, name: location.name, id: location.id});
                            setInfoWindowPosition(marker);
                        }} 
                        
                        icon={{
                            url: (require('../Assets/Images/blue-dot.png')),
                          scale: 1
                        }}
                    />
                );
            });
            setMarkers(markersList)
        }
    }

    const windowCloseHandler = function () {
        setShowInfoWindow({ visible: false, name: "", id: 0 });
    }

    return (
        <LoadScript googleMapsApiKey="AIzaSyBP_J5w_dEq0pxQTJuI0apXs9d2v8S6rvk">
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={4}>
                {markers}
                {showInfoWindow.visible && <InfoWindow key="1" position={infoWindowPosition} onCloseClick={windowCloseHandler} >
                    <>
                        <span className="map-location-name">{showInfoWindow.name}</span>
                        <div className="map-user-picks text-center mt-3">
                           <Button onClick={selectionHandler}>Select Location</Button>
                        </div>
                    </>
                </InfoWindow>}
            </GoogleMap>
        </LoadScript>
    );
}

export default MyPicksMap;
