import React from "react";
import { useParams } from 'react-router-dom';
import { Card, Col, Form, Row, Table, Spinner} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link, useNavigate  } from "react-router-dom";
import PlayerAward from '../Components/PlayerAward';
import SEO from '../Components/Seo';
import { useWindowDimension } from '../Hooks/useWindowDimension';
import { PlayerScoresGraph } from '../Components/PlayerScoresGraph';
import PlayerPicksDistribution from '../Components/PlayerPicksDistribution';
import {setupDaysOutChartLabels, setupDistrubutionChartLabels, setupDistrubutionChartData, setupDaysOutChartData} from '../Helper'

function Profile(){

    const [profileData, setProfileData] = useState({seasonPicks: []});
    const [pageLoaded, setPageLoaded] = useState(false);
    const [awardsContent, setAwardsContent] = useState([]);
    const [width] = useWindowDimension();
    const {id}  = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        fetch("/page/profile/" + id)
            .then((response) => {   
                return response.json();
            })
            .then((data) => {
                loadData(data);
            })
            .catch(() => {
                
            })
            .finally(() => {
                setPageLoaded(true);
            });
    }, []);  

    function loadData(data){
        
        if(data.success){
            
            setProfileData(data);

            if(data.awards.length > 0){
                const awards = data.awards.map((x, index) => (
                    <PlayerAward key={index} awardID={x.awardID} seasonName={x.seasonName} awardName={x.awardName} />
                ));

                setAwardsContent(<Row>{awards}</Row>);
            }
            else{
                setAwardsContent(<p className="mb-0">{id} does not yet have any awards.</p>)
            }
        }
        else{
            navigate("/");
        }
    }     
    
    function buildLocationNameCell(pick){

        let limit = 100;

        if(width < 800)
        {
            limit = 10;
        }

        return (
            <>
                {pick.locationName.length <= limit ? pick.locationName : pick.locationName.substring(0, limit) + "..."}
            </>
        )
    }

    return (
        <>
            {!pageLoaded && <Row><Col className='text-center'><Spinner className='login-loading' animation="border" /></Col></Row>}
            {pageLoaded && <>
            <SEO 
                title={"FantasyBlizzard.com -- " + id + " Profile"}
                description={"Player profile for " + id}
                crawl={true} 
            />
            <Row className='mb-2'>
                <Col>
                    <h1>{id}</h1>
                </Col>
            </Row>   
            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Header className="bg-dark text-white">Player Info</Card.Header>
                        <Card.Body>
                            <Form>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control value={profileData.profile.name} disabled />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="rookieSeason">
                                        <Form.Label>Rookie Season</Form.Label>
                                        <Form.Control value={profileData.profile.rookieSeason} disabled />
                                    </Form.Group>                            
                                </Row>

                                <Row className="mb-3">

                                    <Form.Group as={Col} controlId="experience">
                                        <Form.Label>Experience</Form.Label>
                                        <Form.Control value={profileData.profile.experience} disabled />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="location">
                                        <Form.Label>Location</Form.Label>
                                        <Form.Control value={profileData.profile.location} disabled />
                                    </Form.Group>
                                    
                                </Row>

                                <Form.Group as={Col} controlId="occupation">
                                        <Form.Label>Occupation</Form.Label>
                                        <Form.Control value={profileData.profile.occupation} disabled />
                                    </Form.Group>

                                <Form.Group className="mb-3" controlId="interests">
                                    <Form.Label>Interests</Form.Label>
                                    <Form.Control value={profileData.profile.interests} disabled/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="bio">
                                    <Form.Label>Bio</Form.Label>
                                    <Form.Control as="textarea" value={profileData.profile.bio} rows={3} disabled />
                                </Form.Group>
                        
                            </Form> 
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            {profileData.seasonPicks.length > 0 && <>
            <Row className="mb-3">
                <Col>
                    <Card style={{ height: '420px' }}>
                        <Card.Header className="bg-dark text-white">Current Season Picks Graph</Card.Header>
                        <Card.Body>
                            <PlayerScoresGraph picks={profileData.seasonPicks} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm="12" lg="6">
                    <Row className="mb-3">
                        <Col>
                            <Card style={{ height: '420px' }}>
                                <Card.Header className="bg-dark text-white">Season Picks Distribution</Card.Header> 
                                <Card.Body>
                                    <PlayerPicksDistribution labels={setupDistrubutionChartLabels(profileData.seasonPicks)} data={setupDistrubutionChartData(profileData.seasonPicks)} labelText=" Number of picks"/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>                    
                </Col>
                <Col sm="12" lg="6">
                    <Row className="mb-3">
                        <Col>
                            <Card style={{ height: '420px' }}>
                                <Card.Header className="bg-dark text-white">Season Picks Days Out Distribution</Card.Header>
                                <Card.Body>
                                    <PlayerPicksDistribution labels={setupDaysOutChartLabels()} data={setupDaysOutChartData(profileData.seasonPicks)} labelText=" Number of picks"/> 
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>                    
                </Col>
            </Row>               
            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Header className="bg-dark text-white">Current Season Picks Table</Card.Header>
                        <Card.Body>
                            <Table striped bordered hover size="sm" responsive>
                                <thead>
                                    <tr className="table-secondary">
                                        <th className='text-center align-middle'>Date</th>
                                        <th className='text-center align-middle'>Location</th>
                                        <th className='text-center align-middle'>Snow</th>
                                        <th className='text-center align-middle'>HDD</th>
                                        <th className='text-center align-middle'>Wind</th>
                                        <th className='text-center align-middle'>Days Out</th>
                                        <th className='text-center align-middle'>Raw Score</th>
                                        <th className='text-center align-middle'>Total Score</th>                        
                                    </tr>
                                </thead>
                                <tbody>
                                { profileData.seasonPicks.map((x, index) => (
                                    <tr key={index}>
                                        <td className="text-center">{x.forecastDateString}</td>
                                        <td className="text-center">
                                            <Link className="table-link" to={"/location/" + x.stationIdentifier + "/" + x.locationName} title={x.locationName}>{buildLocationNameCell(x)}</Link> 
                                        </td>
                                        <td className="text-center align-middle">{x.snow}&quot;</td>
                                        <td className="text-center align-middle">{x.wind}</td>
                                        <td className="text-center align-middle">{x.hdd}</td>
                                        <td className="text-center align-middle">{x.daysOut}</td>
                                        <td className="text-center align-middle">{x.rawScore}</td>
                                        <td className="text-center align-middle">{x.totalScore}</td>                                
                                    </tr>
                                    ) )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </>}
            {profileData.seasonPicks.length === 0 && <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Header className="bg-dark text-white">Current Season Picks</Card.Header>
                        <Card.Body>
                            {id} hasn&apos;t made any picks yet this season 
                        </Card.Body>
                    </Card>
                
                </Col>    
            </Row>}  
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="bg-dark text-white">Awards and Achievements</Card.Header>
                        <Card.Body>
                            { awardsContent }
                        </Card.Body>
                    </Card>     
                </Col>
            </Row>
            
            </>}           
        </>
    );
}

export default Profile;