import React from "react";
import { Alert, Button, Col, Row, Form } from "react-bootstrap";
import { useRef, useState } from 'react';
import SEO from '../Components/Seo';
import { Link } from "react-router-dom";

function ResetPassword (){

    const emailAddressRef = useRef();
    const [resetPasswordSent, setResetPasswordSent] = useState(false);

    const submitHandler = function(e){
        e.preventDefault();
        e.stopPropagation();

        const emailAddress = emailAddressRef.current.value;

        if(emailAddress.length > 0){
            
            var body = {
                username: emailAddress
            }

            fetch("auth/resetpassword", {
                method: "POST", 
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
              })
            .then(() => {
                setResetPasswordSent(true);
                emailAddressRef.current.value = "";
            })            
            .catch(() => {
                
            })
            .finally(() => {

            });  
        }
    };    

    return(
        <>
            <SEO 
                title="FantasyBlizzard.com -- Reset Password"
                description="Did you forget your password, reset it here"
                crawl={true} 
            />
            <Row>
                <Col>
                    <h1>Reset Password</h1>
                    <p>Did you forgot your password?  No worries, please enter the email address associated with your account below and we&apos;ll email you a new password.  If you forgot your username, please <Link to="/contact">Contact Us</Link> for additional support.</p>
                    
                    <Form>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Username/Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" ref={emailAddressRef} /> 
                        </Form.Group>                

                        <Button variant="primary" type="submit" onClick={submitHandler}>
                            Send New Password
                        </Button>
                    </Form>
                </Col>
            </Row>
            { resetPasswordSent && <Row className="mt-3">
                <Col>                
                    <Alert key="1" variant="success">
                        If there&apos;s an account associated with the email address you submitted, a email is currently on its way.  Please give our low budget servers a few minutes to send the email. If you don&apos;t receive an email, please <Link to="/contact">Contact Us</Link>.
                    </Alert>
                </Col>
            </Row>}
        </>
    )
}

export default ResetPassword;