import React, { useEffect, useState, useRef } from 'react';
import {Alert, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import PicksMap from '../Components/PicksMap';
import DatePlayerScoresTable from '../Components/DatePlayerScoresTable';
import SEO from '../Components/Seo';
import PlayerPicksDistribution from '../Components/PlayerPicksDistribution';
import {setupDaysOutChartLabels, setupDistrubutionChartLabels, setupDistrubutionChartData, setupDaysOutChartData} from '../Helper'

function Scoreboard(){

    let date = new Date();

    const incomingDate = localStorage.getItem("scoreboard_date");
    if(incomingDate != null && incomingDate.length > 0){
        localStorage.setItem('scoreboard_date', "");
        date = new Date(incomingDate);
    }

    const dateRef = useRef();
    const [viewingDate, setViewingDate] = useState(date.toISOString().split('T')[0]);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const [data, setData] = useState([]);

    const calendarDate = date.toISOString().split('T')[0];
    const todayDate = new Date().toISOString().split('T')[0];

    useEffect(() => {        
        const interval = setInterval(() => { 
            fetchData();
        }, 300000); 
        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        fetchData();
    }, [viewingDate]);

    function fetchData(){ 
        
        fetch("scores/player/" + viewingDate)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setData(data);
        })
        .catch(() => {

        })
        .finally(() => {
            setPageLoaded(true);
            setLastUpdated(new Date());
        });
    }

    function getFormattedDate(inputDate) {

        var splitDate = inputDate.split('-');
        if(splitDate.count === 0){
            return null;
        }
    
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2]; 
    
        return month + '/' + day + '/' + year;
    }

    function dateChangedHandler(){        
        setViewingDate(dateRef.current.value);
    }

    return (
        <>
        <SEO 
            title={"FantasyBlizzard.com -- Daily Scoreboard For " + getFormattedDate(viewingDate)}
            description={"Fantasy Blizzard player scores and picks for " + getFormattedDate(viewingDate)}
            crawl={true} 
        />
            <Row>
                <Col>
                    <h1>Scoreboard</h1>
                    <p>If you&apos;re interested in knowing what&apos;s happening in Fantasy Blizzard today or some other day way back when, you&apos;ve come to the right place. This page will automatically refresh every few minutes so it&apos;s a good page to keep open in a tab to keep track of a pick you&apos;ve made today or another player&apos;s pick you&apos;re wishing to bust. Use the date picker below to view another date.</p>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="date" ref={dateRef} onChange={dateChangedHandler} defaultValue={calendarDate} max={todayDate} /> 
                        </Form.Group>
                    </Form>  
                    
                </Col>                
            </Row>
            <Row className="mb-3">
                <Col sm="12">
                    <Card>
                        <Card.Header className="bg-dark text-white"><span className="header-text">Scores on { getFormattedDate(viewingDate)}</span></Card.Header>
                        <Card.Body>
                            {!pageLoaded && <Row><Col className='text-center'><Spinner className='login-loading' animation="border" /></Col></Row>}
                            {pageLoaded && data.length > 0 && <>                            
                            <Row className='mb-3'>
                                <Col style={{ height: '410px' }} sm="12">
                                    <PicksMap date={viewingDate}/>
                                </Col>
                            </Row> 

                            <Row className="mb-3">
                                <Col sm="12" lg="6">
                                    <Row className="mb-3">
                                        <Col>
                                            <Card style={{ height: '420px' }}>
                                                <Card.Header className="bg-dark text-white">Location Distribution</Card.Header>
                                                <Card.Body>
                                                    <PlayerPicksDistribution date={viewingDate} labels={setupDistrubutionChartLabels(data)} data={setupDistrubutionChartData(data)} labelText=" Number of picks"/>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>                    
                                </Col>
                                <Col sm="12" lg="6">
                                    <Row className="mb-3">
                                        <Col>
                                            <Card style={{ height: '420px' }}>
                                                <Card.Header className="bg-dark text-white">Days Out Distribution</Card.Header>
                                                <Card.Body>
                                                    <PlayerPicksDistribution labels={setupDaysOutChartLabels()} data={setupDaysOutChartData(data)} labelText=" Number of picks"/> 
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>                    
                                </Col>
                            </Row>

                            {todayDate === viewingDate && < Row>
                                <Col>
                                    <Alert key="1" variant="warning">
                                        <strong>Note:</strong> Scores for today are estimated.
                                    </Alert>
                                </Col>
                            </Row>}
                            <Row>
                                <Col sm="12" className="text-right">
                                    <strong>Last Updated:</strong> {lastUpdated.toLocaleTimeString()}
                                </Col>
                                <Col sm="12">
                                    <DatePlayerScoresTable data={data} date={viewingDate} text={getFormattedDate(viewingDate)} />
                                </Col>
                            </Row>
                            </>}
                            {pageLoaded && data.length == 0 && <>
                            <span>There were no picks for {todayDate === viewingDate ? "today" : "this day"}.</span>
                            </>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default Scoreboard;