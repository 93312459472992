import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

function SortableTH(props){

    const getIconCssClass = function(){  
        return "sort-icon " + (props.field !== props.currentSort.column ? "hidden-sort-icon" : "sorting-icon") + (props.currentSort.ascending ? " ascending" : "");
    }

    const getTableHeader = () => {

        if(!props.sortDisabled){
            return <th className={(props.alignLeft ? "text-left" : "text-center") + " align-middle pointer"} onClick={() => props.sortData(props.field)}>{props.text}<span className={getIconCssClass()}><FontAwesomeIcon icon={props.currentSort.ascending ? faSortUp : faSortDown} /></span></th>;
        }
        else{
            return <th className={(props.alignLeft ? "text-left" : "text-center") + " align-middle"}>{props.text}</th>;
        }        
    }

    return (
        getTableHeader()
    );
}

export default SortableTH;