import React from 'react';
import { OverlayTrigger, Tooltip} from 'react-bootstrap';
import { faCheck, faCircleXmark,  faEraser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PicksMulliganButton(props){

    const displayMessage = determineMessage();
    const button = buttonToDisplay();

    function determineMessage () {
        
        let message = "";

        if(props.pick.isEligibleForMulligan){
            message = "You can use your mulligan on this pick until " + props.pick.mulliganExpirationDateString;
        }
        else if(props.pick.mulliganDateTime != null){
            message = "You used your mulligan on this pick";
        }
        else {
            message = "This pick is no longer eligible for a mulligan";
        }

        return message;
    }

    function buttonToDisplay () {
        
        let button = [];

        if(props.pick.isEligibleForMulligan){
            button = <FontAwesomeIcon className='green-icon pointer' icon={faEraser} onClick={() => props.showHandler(props.pick)} />;
        }
        else if(props.pick.mulliganDateTime != null){
            button = <FontAwesomeIcon className='green-icon' icon={faCheck} />;
        }
        else {
            button = <FontAwesomeIcon className='red-icon' icon={faCircleXmark} />;
        }

        return button;
    }

    //function showModal(pick)

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          { displayMessage }
        </Tooltip>
      );

    return (
        <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
        {button}
      </OverlayTrigger>     
    );
}

export default PicksMulliganButton;