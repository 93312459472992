import React, { useEffect, useState, useRef } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import Standing from '../Components/Standing';
// import Loading from '../Components/Loading';
// import { useNavigate  } from "react-router-dom";
import SEO from '../Components/Seo';
import Loading from '../Components/Loading';
import Standing from '../Components/Standing';

function PastSeasons(){

    const seasonRef = useRef();
    const [loadingState, setLoadingState] = useState(0);
    const [selectedSeason, setSelectedSeason] = useState("");
    const [seasons, setSeasons] = useState();
    const [standings, setStandings] = useState();
    // const navigate = useNavigate();
    // const [content, setContent] = useState();

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {

        fetch("/page/past-seasons/", {
            method: 'GET'})
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setSeasons(data);   
                setLoadingState(1);             
            })
            .catch(() => {

            })
            .finally(() => {

            });
    }

    const seasonChanged = (e) => {
        setLoadingState(2);  
        const seasonID = seasonRef.current.value;

        if(seasonID !== 0){

            setSelectedSeason(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text);
            fetch("standings/past-season/" + seasonRef.current.value, {
                method: 'GET'})
                .then((response) => {
                    return response.json();
                })
                .then((data) => { 
                    setLoadingState(3);         
                    setStandings(data);    
                })
                .catch(() => {

                })
                .finally(() => {

                });
        }
    }

    return (
        <>
            <SEO 
                title="FantasyBlizzard.com -- Past Seasons"
                description="Past Fantasy Blizzard seasons"
                crawl={false} 
            />
            <h1>Past Seasons</h1>
            <Row>
                <Col sm="12">
                    <p>Are you curious what happened way back when in Fantasy Blizzard? You&apos;ve come to the right place. Select a season to view the final standings. Note, display names in past seasons reflect current names and not the name of players at the time.</p>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    { loadingState == 0 && <Loading text="Loading picks" /> }
                    {loadingState > 0 && <Form>                    
                        <Form.Group className="mb-3" controlId="knowledge">
                            <Form.Label>Season</Form.Label> 
                            <Form.Select ref={seasonRef} defaultValue={0} onChange={seasonChanged}>
                                <option key={-1} value={0}>Select</option>
                                {
                                    seasons.map((x, index) => (
                                        <option key={index} value={x.id}>{x.name}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                    </Form>
                    }
                </Col>
            </Row>
            { loadingState == 2 && <Loading text="Loading picks" /> }
            { loadingState == 3 && <Card>
                <Card.Header className="bg-dark text-white">{selectedSeason}</Card.Header>
                <Card.Body>
                    <Standing standingItems={standings} /> 
                </Card.Body>
            </Card>}
        </>
    );
}
export default PastSeasons;