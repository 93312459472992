import React from 'react';
import {Col, Row} from 'react-bootstrap';
import SEO from '../Components/Seo';

export default function About() {

  return (
    <>
        <SEO 
            title="FantasyBlizzard.com -- About & Rules" 
            description="Fantasy Blizzard about us and official rules to play" 
            crawl={true} 
        />
      <Row>
        <Col className="col-12">
            <h1>About Fantasy Blizzard</h1>
        </Col>

        <Col className="col-12">
            <p>Fantasy Blizzard is a winter forecasting game that was started and played by a small group of competitive meteorology students at the State University of New York at Oswego. It slowly grew over its first couple early season and has since made it to the national level where people of all levels and skill sets of meteorology compete.</p>
            <p>We have National Weather Service employees, television meteorologist personalities, students, and those who know very little about the weather participating in the game. If you can derive the vorticity equation, this game is for you. If you don&apos;t know the difference between graupel and hail, this game is also for you!</p>

            <h3>How It works</h3>
            <p>Each day during the season, you may select one location from our set of over 200 locations (ASOS stations) across the continental United States where you think winter weather will strike. A score is determined based on how much snow accumulated, how strong the winds were, and how cold the day was. An average is calculated based on all of your submission scores and if you have the highest average at the end of the season and have met the minimum forecast submission requirement, you win!</p>

            <h3>Official Rulebook</h3>

            <p>Below are listed the current rules of Fantasy Blizzard.</p>

            <ul>
                <li>The Fantasy Blizzard season runs from December 1st - February 28/29 (inclusive)... December 1st being the first day and March 15th being the last day a submission can made for.</li>
                <li>The game is open to anyone who wants to play.  You don&apos;t have to have any high level education in meteorology.</li>
                <li>It&apos;s free to play!</li>                            
                <li>To qualify to win, you must submit <strong>at least 30</strong> entries during the duration of the contest.</li>
                <li>You don&apos;t need to play every day, just need to reach the required 30 over the season to win and your name and score to be showcased in season archives.</li>
                <li>For each day over the minimum of 30, you will get an extra half-point added to your average.</li>
                <li>Points are calculated by 24 hour snow, highest gust wind speed, and heating degree days based on National Weather Service Daily Climate (CLI) reports, which are from 12:00am to 11:59pm local time. For those stations that do not have Daily Climate Reports, CF6 reports will be used.</li>
                <li>Snow points: 1 point per tenth-inch of reported accumulated snow</li>
                <li>Cold points: 1 point per heating degree day (65-average of max and min temperature)</li>
                <li>Wind points: 1 point per maximum gust mph</li>
                <li>Raw Score Formula: (Snow * 10) + HDD + Wind</li>
                <li>Possible forecast locations are limited to those with CLI reports with the three needed values.</li>
                <li>Only one station can be selected per day.</li>
                <li>Players may lock-in their selection for a day in advanced to qualify for bonus points to be added onto that day&apos;s score. To qualify for these points, a selection must be made at least 2 days in advanced and the player will not be allowed to modify the pick in anyway. Once locked-in, it cannot be changed or cancelled. Below are scoring weights that will be awarded to selections based on the number of days in advance the selection was made.
                    <ul>
                        <li>1 day: 0.0</li>
                        <li>2 day: 0.2</li>
                        <li>3 day: 0.3</li>
                        <li>4 day: 0.4</li>
                        <li>5+ day: 0.5</li>
                    </ul> 

                    To determine the points awarded for the day, the bonus weight will simply be multiplied by the raw score. For example, say a player locked in their selection 4 days in advance and that location ended up scoring 150 points. Their final score would be 150 + (150 * 0.4) = 210

                </li>
                <li>The number of days out is calculated using eastern standard time, even if the station is out west. Once the pick is locked, a lock-in date is recorded (using EST) and the days between when it was locked and the day of the forecast is what determines &quot;days out&quot;.</li>
                <li>Submissions are accepted until 11:59:59 EST for the next day.</li>
                <li>You may use whatever performance enhancing tools you have available.</li>
                <li>Each player may only have one account. Any accounts found in violation will be suspended from the current season.</li>
            </ul>   

            <h5>Mulligans</h5>

            <p>Players will have the ability to mulligan a pick removing it from their pick count and average.</p>

            <ul>
                <li>Each player will only have one (1) mulligan for the entire season.</li>
                <li>The mulligan will completely erase the pick from their score and pick count. For those who use their mulligan, you&apos;ll need to have made a total of 31 picks during the season to reach the minimum pick requirement, but only 30 will reflect in the standings.</li>
                <li>The mulligan must be invoked by the user and will not be automatically applied at the end of the season removing the lowest scoring pick.</li>
                <li>To mulligan a pick, it must be used by the end of the next day of the score being posted.<br />
                    <ol type="i">
                        <li> For example, if the pick was for 12/10, the score will* be posted on 12/11. The user will have until 11:59:59pm ET on 12/12 to decide if the mulligan will be used on that pick.</li>
                        <li>*In the event there is a significant delay in a score being posted, as it occasionally happens due to a component of the score missing, then the time window will be pushed out. An announcement will be made in these special cases.</li>
                    </ol>
                </li>
                <li>To prevent a delay in the standings being finalized for March Mammatus, picks made for any of the last 3 days of the regular season will not be eligible for mulligans. <br />

                    <ol type="i">
                        <li>For non leap year years, February 25rd will be the last day eligible for the mulligan to be used.</li>
                        <li>For leap year years, February 26th will be the last day eligible for the mulligan to be used</li>
                    </ol>
                </li>
                <li>Mulligans do not rollover to the next season</li>
                <li>Mulligans cannot be traded, sold, transferred, etc.</li>
            </ul>
                    
            <h5>Lock-in Safeguard</h5>

            <p>In addition to the weights of the lock-in bonuses being raised from last season, a safeguard will be put into place to prevent players from staying competitive at the top of the standings by always locking out 5+ days in advanced scoring decent points on mundane weather.</p>
            <p>For a pick to be eligible for lock-in bonus points, the total raw score must be greater or equal to 100 points.</p>

            <h5>March Mammatus</h5>
            <p>Fantasy Blizzard will have its own March Madness style tournament at the conclusion of the regular season. We&apos;ll call it March Mammatus to avoid any lawsuits coming our way. If the name is too corny, please send us any alternative names you think would be good. Details as follow.</p>
            
            <ul>

                <li>There will be two winners. A regular season champion, as there is today, and a March Mammatus champion.</li>
                <li>March Mammatus will begin on March 1st, with March 1st being the first day picks will be submitted for.</li>
                <li>The top 32 players who reached the minimum thirty requirement will be seeded from 1-32 into four divisions of eight.
                    <ol type="i">
                        <li>If there&apos;s a large increase in players next season and a 64 player field could be established, we will expand the tournament to 64 players. A decision on that would come later next season.</li>
                        <li>In the event, 32 players do not reach the minimum requirement, we&apos;ll either fill the remaining seeds with players ranked by total picks descending, then by average descending (the way the basement of standings is ordered today) or round one buys will be given. It really depends on how many empty seeds there are.</li>
                        <li>If a pick made in the last few days of the season still doesn&apos;t have its score posted by March 1st and that pick could significantly change a player&apos;s seeding ranking (say someone who&apos;s ranked 33rd overall and it&apos;s likely that missing score would move them into 32nd) an executive decision from the comminisoner&apos;s office will be made on how to proceed at that time.</li>
                        <li>If there are any players tied with exactly the same average at the end of the season, the player with more picks will be seeded higher. In the case where the average and pick count are the same, then a coin toss will take place to determine seeding.</li>
                    </ol>
                </li>
                <li>Each round will last 5 days</li>
                <li>Each round will require players to submit at least 3 picks.
                    <ol type="i">
                        <li>Players who fail to submit 3 picks will automatically forfeit the match-up. If neither player put 3 in, then the player with the highest average will advance. If there&apos;s a tie, tie breaking procedures outlined in 3.4 will apply.</li>
                        <li>No mulligans</li>
                        <li>Lock-ins will be disabled</li>
                    </ol>
                </li>

                <li>A field of 32 players will require 5 rounds to complete, beginning on March 1st.
                    <ol type="i">
                        <li>Round One: 3/1 to 3/5</li>
                        <li>Synoptic Sixteen: 3/6 to 3/10</li>
                        <li>Adiabatic Eight: 3/11 to 3/15</li>
                        <li>Frontogenetical Four: 3/16 to 3/20</li>
                        <li>Thermodynamic Two: 3/21 to 3/25</li>
                    </ol>
                </li>
                <li>Match-ups will be decided by the player with the highest average at the end of day 5
                    <ol type="i">
                        <li>In the event of a tie, the player with the higher seed will be declared winner, unless each player had the same average at the end of the season. </li>
                        <li>If each player had the same final average at the end of the season, the player with the most picks will advance.</li>
                        <li>If the planets happened to be aligned and each player had exactly the same average and pick count, the match-up will be decided by a coin toss streamed live online.</li>
                    </ol>
                </li>
                <li>Since tournament seeding will not be known until the morning of 3/1 and the first day of the tournament is on 3/1, players who think they will have a ticket punched to March Mammatus will need to submit picks for 3/1 on 2/28. The same scenario will occur at the end of each round. Players will need to submit picks for the first day of the next round before the round has been formally decided.</li>
                <li>Players who are not in the tournament or have been knocked out, will still have the ability to make picks for fun.</li>
            </ul>

            <h5>Other</h5>
            <p>Starting for this season, since the league commissioner also plays, he is required to Tweet out any picks he locks in to keep integrity of the game fully checked.</p>

            <span>Revised April 2023</span>
                        
            </Col>
        </Row>
    </>
  )
}