import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SEO from '../Components/Seo';

export default function NotFound() {
  return (
    <>
      <SEO 
            title="FantasyBlizzard.com -- Not Found"
            description="Page not found. Please go back and try again"
            crawl={false} 
        />
      <Row>
        <Col>
            <h1>Not Found</h1>
            <p>Hold up. Looks like we might have a broken link on our hands. Please go back and try again.</p>
        </Col>
      </Row>
    </>
  )
}