import React, {useEffect, useState} from 'react';
import {Card, Spinner} from 'react-bootstrap'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function TopSnowiestLocationsGraph(props) {

  const [data, setData] = useState({});
  const [rawData, setRawData] = useState({});
  const [loadingComplete, setLoadingComplete] = useState(false);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      tooltip: {
        callbacks: {     
          label: (yDatapoint) => {
            return  yDatapoint.dataset.label + " " + yDatapoint.raw.toFixed(1) + "\""
          },
          title: (xDatapoint) => {
            const item = rawData.filter(x => x.index === xDatapoint[0].dataIndex);
 
            if(item.length === 1){
              return item[0].locationName;
            }
            return xDatapoint.raw;
          }
        }
      }
    },  
    scales: {y: { title: { display: true, text: 'Estimated Snow Totals (inches)' }}}
  };

  useEffect(() => {
        const sortedData = sortData(props.data);
    setupChartDataObject(sortedData);    
  }, [props]);

  function sortData(data){
    
    const sortedData = data.sort(function (a, b){
    if (a.snow > b.snow) {
        return -1;
    }
    else if (a.snow < b.snow) {
        return 1;
    }    
    else if(b.locationIdentifier - a.locationIdentifier < 0){
      return -1;
    }
    else{
      return 1;
    }
    
    }).slice(0, props.count);

    return sortedData;
  }

  function setupChartDataObject(locationData){
    locationData = locationData.slice(0, props.count);
    const locations = locationData.map(x => x.locationIdentifier);
    
    const data = {
      labels: locations,
      datasets: [
        {
          label: 'Snow',
          data: locationData.map(x => x.snow),
          backgroundColor: 'rgb(192,192,192)',
        }
      ]
    };

    locationData = locationData.map((obj, index) => ({ ...obj, index: index }))

    setData(data);
    setRawData(locationData);
    setLoadingComplete(true);
  }

  return (
    <Card style={{ height: '420px' }}>
      <Card.Header className="bg-dark text-white">Today&apos;s Top {props.count} Snowiest Locations</Card.Header>
      <Card.Body>
        {!loadingComplete && <Spinner className="card-body-loading" animation="border" />}
        {loadingComplete && <Bar options={options} data={data} /> } 
      </Card.Body>
    </Card>
  );
}

export default TopSnowiestLocationsGraph;