import {Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate  } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import SEO from '../Components/Seo';

function AdminManagement(){

    const [sendingUpdateMessageForm, setSendingUpdateMessageForm] = useState(false);  
    const [currentPageMessage, setCurrentPageMessage] = useState({ PageMessage: { date: new Date(), message: ""}});  

    const pageMessageDate = useRef();
    const pageMessage = useRef();

    const navigate = useNavigate();

    useEffect(() => {

        fetch("/adminUtil/management")
            .then((response) => {

                if(response.status !== 200){
                    navigate("/login");
                } 
                return response.json();
            })
            .then((data) => {
                setCurrentPageMessage(data);
            })
            .catch(() => {
                
            })
            .finally(() => {

            });
    }, []);  

    const formatDate = (date) => {
        return new Date(date).toISOString().split('T')[0]    
    }

    const updatePageMessage = (e) =>{
        e.preventDefault();
        e.stopPropagation();

        setSendingUpdateMessageForm(true);

        var body = {
            date: pageMessageDate.current.value,
            message: pageMessage.current.value
        }

        fetch("/adminUtil/management/update-page-message", {
            method: "POST", 
            headers: {
            "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        .then((response) => {

            if(response.status !== 200){
                navigate("/login");
            }  

            return response.json();
        })
        .then(() => {    
            
        })
        .catch(() => {

        })
        .finally(() => {
            setSendingUpdateMessageForm(false);
        });
    };

    return (
        <>
            <SEO 
                title="FantasyBlizzard.com -- Management" 
                description="" 
                crawl={false} 
            />
            <Row>
                <Col><h1>Management</h1></Col>
            </Row>

            <Row>
                <Col>            
                    <Card>
                        <Card.Header className="bg-dark text-white">Page Message</Card.Header>
                        <Card.Body>

                            <Form>

                                <Form.Group className="mb-3">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control type="date" ref={pageMessageDate} defaultValue={formatDate(currentPageMessage.PageMessage.date)} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={3} ref={pageMessage} defaultValue={currentPageMessage.PageMessage.message} />
                                </Form.Group>

                                <div className="text-right">
                                { sendingUpdateMessageForm && <Spinner className='login-loading mr-3' animation="border" />}   
                                    <Button variant="primary" type="submit" onClick={updatePageMessage}>
                                        Update Message
                                    </Button>  
                                </div>   
                            </Form>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        
        </>
    )
}

export default AdminManagement;