import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import {Nav, NavDropdown} from 'react-bootstrap/';
import Navbar from 'react-bootstrap/Navbar';
import { AuthContext } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake, faTableCells, faChartLine, faTableList, faCircleInfo, faKey, faRightFromBracket, faGears } from '@fortawesome/free-solid-svg-icons'

export default function MainNav() {

  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const { level, setLevel } = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {

    setIsLoggedIn(false);

    fetch("/auth/ping", {
      method: "POST"
    })
      .then((response) => {
            
        return response.json();
      })
      .then((data) => {
        setIsLoggedIn(data.status);
        setLevel(data.level);
      })
      .catch(() => {
          
      })
      .finally(() => {
          
      });
   
}, []);  

  return (
    <Navbar expanded={expanded} collapseOnSelect expand="lg" bg="dark" variant="dark" className="p-3 mb-3">
      <Container>
        <Navbar.Brand href="/">Fantasy Blizzard</Navbar.Brand>
        <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            { isLoggedIn && <div className='navLink'>
              {' '}
              <Link onClick={() => setExpanded(false)} className="text-decoration-none text-white" to="/my-picks">
                <FontAwesomeIcon icon={faSnowflake} /> My Picks
              </Link>
            </div>}
            <div className='navLink'>
              {' '}
              <Link onClick={() => setExpanded(false)} className="text-decoration-none text-white" to="/scoreboard">
               <FontAwesomeIcon icon={faTableCells} /> Scoreboard
              </Link>
            </div>
            <div className='navLink'>
              {' '}
              <Link onClick={() => setExpanded(false)} className="text-decoration-none text-white" to="/live-scores">
               <FontAwesomeIcon icon={faChartLine} /> Live Scores
              </Link>
            </div>
            <div className='navLink'>
              {' '}
              <Link onClick={() => setExpanded(false)} className="text-decoration-none text-white" to="/full-standings">
              <FontAwesomeIcon icon={faTableList} /> Standings
              </Link>
            </div>
            <div className='navLink'>
              {' '}
              <Link onClick={() => setExpanded(false)} className="text-decoration-none text-white" to="/about">
              <FontAwesomeIcon icon={faCircleInfo} /> About
              </Link>
            </div>
            {isLoggedIn && <div className='navLink'>
              {' '}
              <Link onClick={() => setExpanded(false)} className="text-decoration-none text-white" to="/my-account">
              <FontAwesomeIcon icon={faGears} /> My Account
              </Link>
            </div>}
            {!isLoggedIn && <div className='navLink'>
              {' '}
              <Link onClick={() => setExpanded(false)} className="text-decoration-none text-white" to="/register">
              <FontAwesomeIcon icon={faGears} /> Register
              </Link>
            </div>}
            <div className='navLink'>
              {' '}
              {!isLoggedIn && <Link onClick={() => setExpanded(false)} className="text-decoration-none text-white" to="/login">
                <FontAwesomeIcon icon={faKey} /> Login
              </Link> }
              {isLoggedIn && <Link onClick={() => setExpanded(false)} className="text-decoration-none text-white" to="/logout">
                <FontAwesomeIcon icon={faRightFromBracket} /> Logout
              </Link> }    
            </div>
            {level === 2 && <NavDropdown title="Admin" id="adminNav">
              <NavDropdown.Item href="/admin/services">Services</NavDropdown.Item>
              <NavDropdown.Item href="/admin/scores">Add Scores</NavDropdown.Item>
              <NavDropdown.Item href="/admin/management">Management</NavDropdown.Item>
              <NavDropdown.Item href="/admin/users">Users</NavDropdown.Item>
            </NavDropdown>
            }
          </Nav>         
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}