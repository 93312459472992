import React from 'react'
import { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import Loading from '../Components/Loading'
import LoadingError from '../Components/LoadingError'
import { Link } from 'react-router-dom';
import { useWindowDimension } from '../Hooks/useWindowDimension';
import {determineCurrentObservationIcon, determmineTemperatureColor} from '../Helper'

function TodaysPicksTable(props){
    const [width] = useWindowDimension();
    const [content, setContent] = useState();

    useEffect(() => {

        setContent(<Loading text="Loading picks" />);

        fetch("picks/getToday")
            .then((response) => {

                if (!response.ok) {
                    setContent(<LoadingError />);
                }
                return response.json();
            })
            .then((data) => {
                buildTable(data);
            })
            .catch(() => {
                setContent(<LoadingError />);
            })
            .finally(() => {

            });
    }, []);  

    function buildWeatherCell(pick){
        
        let currentWeatherIcon = determineCurrentObservationIcon(pick.currentWeatherIcon, pick.currentWeather);
        let currentWeatherText = [];

        if(width >= 800){
            currentWeatherText = [<span key={1} className="weather-item">{pick.currentWeather.trim().length > 0 ? pick.currentWeather : "Not available" } {currentWeatherIcon}</span>];
        }
        else{
            currentWeatherText = [<span key={1} className="d-block">{currentWeatherIcon}</span>];
        }

        const styles = {
            color: determmineTemperatureColor(pick.currentTemperature)
         }

        if(width >= 800){
            return (
                <Row className="current-weater-row">
                    <Col sm="12" md="5" className="text-right mr-0 pr-0">{currentWeatherText}</Col>
                    <Col sm="12" md="2" className="text-center mr-0 ml-0 pl-0 pr-0"><span style={styles}>{pick.currentTemperature}°F</span></Col>
                    <Col sm="12" md="5" className="text-left ml-0 pl-0">{pick.currentWind}</Col>
                </Row>
            );
        }
        else{
            return (
                <Row className="current-weater-row">
                    <Col sm="12" md="5" className="text-center">{currentWeatherText}</Col>
                    <Col sm="12" md="2" className="text-center"><span style={styles}>{pick.currentTemperature}°F</span></Col>
                    <Col sm="12" md="5" className="text-center">{pick.currentWind.replace("mph", "")}</Col>
                </Row>
            );
        }
    }

    function buildDisplayNameCell(pick){

        let limit = 100;

        if(width < 750)
        {
            limit = 10;
        }
        else if(width < 1000)
        {
            limit = 15;
        }
         
        return (
            <>
                {pick.displayName.length <= limit ? pick.displayName : pick.displayName.substring(0, limit) + "..."}
            </>
        )
    }

    function buildLocationNameCell(pick){

        let limit = 100;

        if(width < 750)
        {
            limit = 10;
        }
        else if(width < 1000)
        {
            limit = 15;
        }

        return (
            <>
                {pick.locationName.length <= limit ? pick.locationName : pick.locationName.substring(0, limit) + "..."}
            </>
        )
    }

    const buildTable = function (data) {

        let rowsContent = [];

        if(data.length > 0){
            rowsContent = data.map((x, index) => (
                <tr key={index}>
                    <td className="pl-3 align-middle">
                        <Link className="user-profile-link" to={"/profile/" + x.displayName} title={x.displayName}>{buildDisplayNameCell(x)}</Link>
                    </td>
                    <td className="text-center align-middle">
                        <Link className="table-link" to={"/location/" + x.stationIdentifier + "/" + x.locationName} title={x.locationName}>{buildLocationNameCell(x)}</Link>
                    </td>
                    <td className="text-center align-middle">{x.hasLiveScore ? buildWeatherCell(x) : "Not available" }</td>
                    <td className="text-center align-middle">{x.daysOut}</td>
                    <td className="text-center align-middle">{x.winterScore}</td>
                </tr>
            ));

            const fullTable = [
                <>
                    <Table key="1" striped bordered hover size="sm" responsive>
                        <thead>
                            <tr className='table-secondary'>
                                <th className="pl-3 align-middle"><span>Name</span></th>
                                <th className="text-center align-middle">Location</th>
                                <th className="text-center align-middle">Current Weather</th>
                                <th className="text-center align-middle">Days Out</th>
                                <th className="text-center align-middle">Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowsContent}
                        </tbody>
                    </Table>
                    <div className="text-right"><small>All scores are estimated.</small></div>
                </>
            ];
    
            setContent(fullTable);
        }
        else{
            setContent(<span className="no-records-text">There are no picks for today.</span>);
        }

        props.setPicksTotal(data.length);
    }
    return (content);
}

export default TodaysPicksTable;