import React from 'react';
import { useRef, useState } from 'react';
import {Alert, Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import RACAPTCHA from "react-google-recaptcha";
import SEO from '../Components/Seo';

export default function Contact() {

  const [validationError, setValidationError] = useState("");
  const [sendingForm, setSendingForm] = useState(false);    
  const [formSentSuccess, setFormSentSuccess] = useState(false);

  const emailAddressRef = useRef();
  const subjectRef = useRef();
  const messageRef = useRef();
  const captchaRef = useRef(null);  

  function submitHandler(e){
    e.preventDefault();
    const token = captchaRef.current.getValue();    

    setFormSentSuccess(false);

    const errors = checkForm();

    if(errors.length === 0){

      setSendingForm(true);

      var body = {
        email: emailAddressRef.current.value.trim(),
        subject: subjectRef.current.value.trim(),
        message: messageRef.current.value.trim(),
        token: token
    }

    fetch("utils/contact", {
        method: "POST", 
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      })
        .then((response) => {
          
            if (response.status !== 200) {
              setValidationError("An error occurred while trying to send the form. Please reload the page and try again.");
            }

            return response.json();
        })
        .then((data) => {   
                  
          if(data.success){

            emailAddressRef.current.value = "";
            subjectRef.current.value = "";
            messageRef.current.value = "";

            captchaRef.current.reset();

            setFormSentSuccess(true);
          }
          else{
            setValidationError(data.message);
          }
        })
        .catch(() => {
            
        })
        .finally(() => {
            setSendingForm(false);
        });    
    }
  }

  function checkForm(){
 
    let error = "";        

    if(emailAddressRef.current.value.trim().length === 0){
        error = "You forgot to enter an email address.";
    }
    else if(subjectRef.current.value.trim().length === 0){
        error = "Please enter a subject.";
    }   
    else if(messageRef.current.value.trim().length === 0){
      error = "Please enter a message.";
    }      

    setValidationError(error);

    return error;
  }

  return (
    <>
    <SEO 
        title="FantasyBlizzard.com -- Contact Us" 
        description="Contact us with any questions, comments, or concerns. Send us a message" 
        crawl={true} 
    />
    <Row>
      <Col>
        <h1>Contact</h1>
          <p>Have a question, comment, or a concern about Fantasy Blizzard? Perhaps an incorrect snow, wind, or temperature score was reported? Please send us a message using the form below.</p>
          <Form>
              <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Your Email</Form.Label>
                  <Form.Control type="text" placeholder="Your email address" ref={emailAddressRef} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="contact">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control type="text" placeholder="Subject" ref={subjectRef} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="bio">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={5} ref={messageRef} />
              </Form.Group>

              <RACAPTCHA className='mb-3' sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />

              <Button variant="primary" type="submit" onClick={submitHandler}>
                  Send
              </Button>     
              { sendingForm && <Spinner className='login-loading' animation="border" />}                 
          </Form>  
      </Col>      
    </Row>
    { validationError.length > 0 && <Row className="mt-3">
    <Col>                
        <Alert key="1" variant="danger">
            {validationError} 
        </Alert>
    </Col>
    </Row>}
    { formSentSuccess && <Row className="mt-3">
    <Col>                
        <Alert key="1" variant="success">
            Thanks for contacting us. We&apos;ll get back to you soon!
        </Alert>
    </Col>
    </Row>}
  </>
  )
}