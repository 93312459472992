import React from 'react';
import {useContext} from 'react';
import { AuthContext } from '../App';
import { useNavigate  } from "react-router-dom";

function Logout(){

    const { setIsLoggedIn } = useContext(AuthContext);
    const { setLevel } = useContext(AuthContext);  

    const navigate = useNavigate();

    fetch("auth/logout", {
        method: "POST"
      })
        .then(() => {        

        })
        .catch(() => {
            
        })
        .finally(() => {
            navigate("/login");
            setIsLoggedIn(false);
            setLevel(0);
        });    

    return (<><h1>Logging Out</h1><p>Please wait....</p></>);
}

export default Logout;