import React from 'react';
import {Tab, Tabs } from 'react-bootstrap';
import TournamentBracket16 from '../Components/TournamentBracket16';
import ProfileLink from '../Components/ProfileLink';
import { useWindowDimension } from '../Hooks/useWindowDimension';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loading from './Loading';

function Tournament(){

    const [width,] = useWindowDimension();
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [content, setContent] = useState();

    useEffect(() => {

        fetch("page/tournament")
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                buildContent(data.tournament);
            })
            .catch(() => {
                
            })
            .finally(() => {
                setLoadingComplete(true);
            });
    }, []);  

    function buildTournamentTabRound(round){
    
        return <Tab eventKey={round.id} title={round.name}>
  
          <div className='tournament-round-container'>
  
            <div className="flex-container tournament-matchup-header">
              <div className="tournament-round-header tournament-player-name-container">{width > 750 ? "Matchup" : "Match"}</div>
              {
                  width > 750 ? round.dates.map((x, index) => <div key={index} className="tournament-round-header tournament-day-container">{x}</div>) : round.shortDates.map((x, index) => <div key={index} className="tournament-round-header tournament-day-container">{x}</div>)
              }
            </div>
  
            {
             buildMatchup(round.matchups)
            }  
          </div>    
  
        </Tab>;
    }      

    function buildMatchup(matchups){

        if(matchups.length > 0){
            return  (matchups.map((matchup, index) =>             
            <div key={index} className='tournament-matchup'>
            <div className="flex-container tournament-away-matchup">
                <div className="tournament-player-name-container">
                <span className="tournament-player-name"><ProfileLink name={matchup.awayDisplayName ?? "TBD"} maxLength={width > 750 ? 20 : 8} /></span>
                </div>              
                {matchup.matchupDays.map(day =>buildMatchupDay(day.awayPick))}
            </div>
            <div className="flex-container tournament-home-matchup">
                <div className="tournament-player-name-container">
                <span className="tournament-player-name"><ProfileLink name={matchup.homeDisplayName ?? "TBD"} maxLength={width > 750 ? 20 : 8} /></span>
                </div>
                {matchup.matchupDays.map(day =>buildMatchupDay(day.homePick))}
            </div>
            <div className='tournament-matchup-summary'>{matchup.summary}</div>
            </div>
            ))
        }

        return [];
    }
  
    function buildMatchupDay(pick){                
        return (            
            <div className="tournament-day-container">
                <div className="tournament-day-pick">
                    {pick !== null ? 
                    <>
                        <span className="tournament-day-score">{pick.score}</span>
                        <span className="tournament-day-pick-location">
                            <Link className="table-link" to={"/location/" + pick.stationIdentifier + "/" + pick.locationName} title={pick.locationName}>{width > 750 ? pick.locationName: pick.stationIdentifier}</Link>
                        </span>
                    </>
                    : <></>}
                </div>
            </div>          
        );
    }

    function buildContent(tournamentData){

        setContent (
            <div className="tournament-card-body-container">                
                <div className="tournamet-bracket">
                    <Tabs>
                        <Tab eventKey="bracket" title="Bracket">
                            <TournamentBracket16 data={tournamentData} />
                        </Tab>
                        {
                        tournamentData.rounds.map(x => buildTournamentTabRound(x))
                        }
                    </Tabs>
                </div>
            </div>
        )
    }

    return (
        <>
        {!loadingComplete && <Loading />}
        {loadingComplete && <>
            {content}
            </>
        }
        </>
    );
}

export default Tournament;