import React from 'react'
import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import Loading from '../Components/Loading'
import LoadingError from '../Components/LoadingError'
import LocationLink from './LocationLink';
import SortableTH from './SortableTH';

function YesterdayTopLocationScores(props){

    const [content, setContent] = useState();

    let locationsData = [];
    let currentSort = {column: "score", ascending: false};

    useEffect(() => {

        setContent(<Loading text="Loading scores" />);

        fetch("scores/yesterday/" + props.count)
            .then((response) => {

                if (!response.ok) {
                    setContent(<LoadingError />);
                }
                return response.json();
            })
            .then((data) => {
                locationsData = data;

                buildTable(data);
            })
            .catch(() => {
                setContent(<LoadingError />);
            })
            .finally(() => {

            });
    }, []);   

    const buildTable = function (data) {

        const rowsContent = data.map((x, index) => (
            <tr key={ index }>
                <td className="pl-3">
                    <LocationLink stationIdentifier={x.stationIdentifier} locationName={x.locationName} maxLength="100" />
                </td>
                <td className="text-center">{x.snow.toFixed(1)}&quot;</td>
                <td className="text-center">{x.wind}</td>
                <td className="text-center">{x.hdd}</td>
                <td className="text-center">{x.rawScore}</td>
            </tr>
        ));

        function sortHandler(field){

            let sortAcending = true;
    
            if(field === currentSort.column){
                sortAcending = !currentSort.ascending;
            }

            currentSort = {column: field, ascending: sortAcending};
            sort(locationsData, field, sortAcending);            
        }

        function sort(data, field, sortAcending){

            const sortedData = data.sort(function(a, b){
                
                let fieldA = a.score; 
                let fieldB = b.score; 
    
                if(field === "name"){
                    fieldA = a.locationName.toUpperCase(); 
                    fieldB = b.locationName.toUpperCase(); 
                }
                else if(field === "wind"){
                    fieldA = a.wind; 
                    fieldB = b.wind; 
                }
                else if(field === "hdd"){
                    fieldA = a.hdd; 
                    fieldB = b.hdd; 
                }
                else if(field === "snow"){
                    fieldA = a.snow; 
                    fieldB = b.snow; 
                }
                else if(field === "score"){
                    fieldA = a.rawScore; 
                    fieldB = b.rawScore; 
                }
    
                if(sortAcending){
                    if (fieldA < fieldB) {
                        return -1;
                    }
                    if (fieldA > fieldB) {
                        return 1;
                    }
                }
                else{            
                    if (fieldA > fieldB) {
                        return -1;
                    }
                    if (fieldA < fieldB) {
                        return 1;
                    }
                }
    
                // names must be equal
                return 0;
            })
    
            buildTable(sortedData, field);
            locationsData = sortedData;
            //setScoresData(sortedData);        
        }

        const fullTable = [

            <Table key="1" striped bordered hover size="sm" responsive>
                <thead>
                    <tr className='table-secondary'>
                        <SortableTH field="name" text="Name" alignLeft={true} currentSort={currentSort} sortData={sortHandler} sortDisabled={props.sortDisabled} />
                        <SortableTH field="snow" text="Snow" currentSort={currentSort} sortData={sortHandler} sortDisabled={props.sortDisabled} />
                        <SortableTH field="wind" text="Wind" currentSort={currentSort} sortData={sortHandler} sortDisabled={props.sortDisabled} />
                        <SortableTH field="hdd" text="HDD" currentSort={currentSort} sortData={sortHandler} sortDisabled={props.sortDisabled} />
                        <SortableTH field="score" text="Score" currentSort={currentSort} sortData={sortHandler} sortDisabled={props.sortDisabled} />
                    </tr>
                </thead>
                <tbody>
                    {rowsContent}
                </tbody>
            </Table>
        ];

        setContent(fullTable);
    }

    return (content);
}

export default YesterdayTopLocationScores;