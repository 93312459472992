import React, {useEffect, useState} from 'react';
import { Spinner} from 'react-bootstrap'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {getFormattedDate} from '../Helper'

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function PlayerScoresGraph(props) {

  const [data, setData] = useState({});
  const [rawData, setRawData] = useState({});
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {

    const sortedData = props.picks.sort(function(a, b){
            
      let fieldA = a.forecastDate; 
      let fieldB = b.forecastDate; 
             
      if (fieldA > fieldB) {
          return 1;
      }
      if (fieldA < fieldB) {
          return -1;
      }     

      return 0;
    });

    setupChartDataObject(sortedData);
  }, []);

  const options = {
    plugins: {
      title: {
        display: false,
        text: '',
      },
      tooltip: {
        callbacks: {     
          label: (yDatapoint) => {
            const series = yDatapoint.dataset.label;
            
            if(series === "HDD"){
              return yDatapoint.dataset.label + " " + yDatapoint.raw;
            }
            else if(series === "Wind"){
              return yDatapoint.dataset.label + " " + yDatapoint.raw + "mph";
            }
            else if(series === "Snow" && yDatapoint.raw > 0){
              return yDatapoint.dataset.label + " " + (yDatapoint.raw * .1) + "\"";
            }
            else{
              return yDatapoint.dataset.label + " " + yDatapoint.raw;
            }
            
          },
          title: (xDatapoint) => {
            const item = rawData.filter(x => x.index === xDatapoint[0].dataIndex);
            
            if(item.length === 1){
              return item[0].locationName;
            }
            else{
              return xDatapoint.raw;
            }
          }
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        title: { display: true, text: 'Points' }
      },
    }
  };
  

  function setupChartDataObject(picks){
  console.log(picks)
    const dates = picks.map(x => getFormattedDate(new Date(x.forecastDate)));

    while(dates.length < 15){
      dates.push("");
    }
    
    const data = {
      labels: dates,
      datasets: [
        {
          label: 'Snow',
          data: picks.map(x => x.snow * 10),
          backgroundColor: 'rgb(192,192,192)',
          maxBarThickness: 20
        },
        {
          label: 'Wind',
          data: picks.map(x => x.wind),
          backgroundColor: 'rgb(75, 192, 192)',
          maxBarThickness: 20
        },
        {
          label: 'HDD',
          data: picks.map(x => x.hdd),
          backgroundColor: 'rgb(0,0,255)',
          maxBarThickness: 20
        },
      ],
    };

    picks = picks.map((obj, index) => ({ ...obj, index: index }));

    setRawData(picks);
    setData(data);
    setLoadingComplete(true);
  }

  return (
      <>
        {!loadingComplete && <Spinner className="card-body-loading" animation="border" />}
        {loadingComplete && <Bar options={options} data={data} /> }    
      </>            

  );
}
