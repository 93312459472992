import React from 'react';
import {Alert, Button, Card, Col, Form, Modal, Row, Table, Spinner} from 'react-bootstrap';
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate  } from "react-router-dom";
import MyPicksMap from '../Components/MyPicksMap';
import MyPicksAlertMessage from '../Components/MyPicksAlertMessage';
import PicksMulliganButton from '../Components/PicksMulliganButton';
import RemovePickButton from '../Components/RemovePickButton';
import SEO from '../Components/Seo'
import { useWindowDimension } from '../Hooks/useWindowDimension';
import DateLink from '../Components/DateLink';
import LocationLink from '../Components/LocationLink';

function MyPicks(){

    const dateRef = useRef();
    const lockInRef = useRef();
    const locationRef = useRef();
    const [validationError, setValidationError] = useState("");
    const navigate = useNavigate();
    const [pageData, setPageData] = useState({});    
    const [pageLoaded, setPageLoaded] = useState(false);
    const [loadedDateTime, setLoadedDateTime] = useState(new Date());
    const [showRemovePickModal, setShowRemovePickModal] = useState({visible: false});
    const [showMulliganModal, setShowMulliganModal] = useState({visible: false});
    const [alertMessage, setAlertMessage] = useState({});
    const [initialLoad, setInitialLoad] = useState(true);
    const [displaySubmitLoading, setDisplaySubmitLoading] = useState(false);
    const [width] = useWindowDimension();

    const date = new Date();
    const defaultDate = new Date(date.setDate(date.getDate() + 1)).toISOString().split('T')[0];

    useEffect(() => {

        fetch("page/user-picks")
            .then((response) => {

                if (response.status === 401) {
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                loadData(data);
            })
            .catch(() => {
                
            })
            .finally(() => {

            });
    }, [loadedDateTime]);  

    function loadData(data){

        if(!data.isActiveForSeason){
            navigate("/my-account");
        }

        setPageData(data);        
        setPageLoaded(true);

        if(initialLoad){
            setAlertMessage({variant: "success", message: "Welcome back, " + data.displayName});
            setInitialLoad(false);
        }
    }

    const submitPickHandler = function(e){
        e.preventDefault();
        e.stopPropagation();

        const errors = checkForm();

        if(errors.length === 0){

            setDisplaySubmitLoading(true);

            var body = {
                date: dateRef.current.value,
                lockedIn: lockInRef.current.value === "true",
                locationID: locationRef.current.value
            }

            fetch("picks/submit", {
                method: "POST", 
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
              })
                .then((response) => {
    
                    if(response.status === 401){
                        navigate("/login");
                    }  

                    return response.json();
                })
                .then((data) => {

                    if(data.success){

                        setLoadedDateTime(new Date());
                        setAlertMessage({variant: "success", message: "Your pick was confirmed. Good luck!"});
                        window.scrollTo(0, 0);

                        dateRef.current.value = defaultDate;
                        lockInRef.current.value = "false";
                        locationRef.current.value = "0";
                    }
                    else{
                        setValidationError(data.message);
                    }
                })
                .catch((error) => {
                    setValidationError(error);
                })
                .finally(() => {
                    setDisplaySubmitLoading(false);
                });

            setValidationError("");
        }
        else{
            setValidationError(errors);
        }
    }

    const checkForm = function(){
        let error = "";        

        if(dateRef.current.value.trim() === ""){
            error = "Please select a date";
        }
        else if(locationRef.current.value.trim() === "0"){
            error = "Please select a location.";
        }       

        return error;
    }

    const selectionHandler = function(data){
        if(data.id >= 0){
            locationRef.current.value = data.id;
        }        
    }

    const handleDelete = function(pick){
        if(!pick.isLocked){
            setShowRemovePickModal({visible: true, pickID: pick.pickID, location: pick.locationName, date: pick.forecastDateString});
        }
    }

    const handleMulliganOpen = function(pick){
        setShowMulliganModal({visible: true, pickID: pick.pickID, location: pick.locationName, date: pick.forecastDateString});
    }

    const removePickHandler = function(){
        
        setShowRemovePickModal({...showRemovePickModal, processing: true})

        var body = {
            pickID: showRemovePickModal.pickID
        }

        fetch("picks/remove", {
            method: "POST", 
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        .then((response) => {

            if(response.status === 401){
                navigate("/login");
            }  

            return response.json();
        })
        .then((data) => {
            
            if(data.success){

                const alertMessage = "Your pick for " + showRemovePickModal.location + " on " + showRemovePickModal.date + " was removed.";

                setAlertMessage({variant: "success", message: alertMessage});
                handleCloseRemovePickModal();
                setLoadedDateTime(new Date());
            }
            else{
                setValidationError(data.message);
            }
        })
        .catch((error) => {
            setValidationError(error);
        })
        .finally(() => {
            
        });
    }

    const useMulliganHandler = function(){
        
        setShowMulliganModal({...showMulliganModal, processing: true})

        var body = {
            pickID: showMulliganModal.pickID
        }

        fetch("picks/mulligan", {
            method: "POST", 
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        .then((response) => {

            if(response.status === 401){
                navigate("/login");
            }  

            return response.json();
        })
        .then((data) => {
            
            if(data.success){

                setAlertMessage({variant: "success", message: "Your mulligan has been used. Please allow a few minutes for the standings to update"});                
                setShowMulliganModal({visible: false});
                setLoadedDateTime(new Date());

                window.scrollTo(0, 0);
            }
            else{
                setValidationError(data.message);
                setShowMulliganModal({...showMulliganModal, processing: false})
            }
        })
        .catch((error) => {
            setValidationError(error);
        })
        .finally(() => {
            
        });
    }

    function buildLocationNameCell(pick){

        let limit = 100;

        if(width < 750)
        {
            limit = 10;
        }

        return (
            <>
                {pick.locationName.length <= limit ? pick.locationName : pick.locationName.substring(0, limit) + "..."}
            </>
        )
    }

    const handleCloseRemovePickModal = () => setShowRemovePickModal({visible: false});

    const handleCloseMulliganModal = () => setShowMulliganModal({visible: false});
    
    return (
        <>
        <SEO 
            itle="FantasyBlizzard.com -- My Picks" 
            description="User picks for Fantasy Blizzard. Select a snowy, cold, and windy location" 
            crawl={false} 
        />
            {!pageLoaded && <Row><Col className='text-center'><Spinner className='login-loading' animation="border" /></Col></Row>}
            {pageLoaded && <>
            <Row className='mb-2'>
                <Col>                    
                    <h1>My Picks</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <MyPicksAlertMessage variant={alertMessage.variant} message={alertMessage.message} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Ready to make a pick? This is the place. Below are all of your tentative and past picks for the current season.</p>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col>
                    <Card>
                        <Card.Header className="bg-dark text-white">Upcoming Picks</Card.Header>
                        <Card.Body>
                            {pageData.upcomingPicks.length > 0 && <Table striped bordered hover size="sm" responsive>
                                <thead>
                                    <tr className="table-secondary">
                                        <th className='text-center'>Date</th>
                                        <th className='text-center'>Location</th>
                                        <th className='text-center'>Locked-In</th>
                                        <th className='text-center'>Delete</th>                           
                                    </tr>
                                </thead>
                                <tbody>
                                    {pageData.upcomingPicks.length > 0 ? pageData.upcomingPicks.map((x, index) => (
                                        <tr key={index}>
                                            <td className="text-center">{x.forecastDateString}</td>
                                            <td className="text-center"><LocationLink stationIdentifier={x.stationIdentifier} locationName={x.locationName} maxLength="100" /></td>
                                            <td className="text-center">{x.isLocked ? "Yes" : "No"}</td>
                                            <td className="text-center"><RemovePickButton pick={x} handleRemove={handleDelete} /></td>
                                        </tr>
                                    )): <tr><td colSpan="4">You don&apos;t have any upcoming picks.</td></tr>}
                                </tbody>
                            </Table> }   
                            {pageData.upcomingPicks.length === 0 && 
                            <>You don&apos;t have any upcoming picks.</>
                            }
                        </Card.Body>
                    </Card>                    
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <Card>
                        <Card.Header className="bg-dark text-white">Make a Pick</Card.Header>
                        <Card.Body>
                            {pageData.picksEnabled && <Row>
                                <Col>
                                    <div className="my-picks-map-container">
                                        <MyPicksMap selectionHandler={selectionHandler} />
                                    </div>
                                    <div className="text-right">
                                        <small>Clicking on a blue location dot will auto select the location below.</small>
                                    </div>                                  

                                    <Form>
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="date" ref={dateRef} min={new Date(pageData.minPickDate).toISOString().split('T')[0]} max={new Date(pageData.maxPickDate).toISOString().split('T')[0]} defaultValue={new Date(pageData.minPickDate).toISOString().split('T')[0]} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="lockIn">
                                            <Form.Label>Lock-In</Form.Label>
                                            <Form.Select ref={lockInRef}>
                                                <option value="false">No</option>
                                                {!pageData.inTournamentMode && <option value="true">Yes</option>}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="knowledge">
                                            <Form.Label>Location</Form.Label>
                                            <Form.Select ref={locationRef}>
                                                <option value="0">Select</option>
                                                {pageData.locations.map((x, index) => <option key={index} value={x.id}>{x.name}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                        <Button variant="primary" type="submit" onClick={submitPickHandler}>
                                            Submit
                                        </Button>
                                        {displaySubmitLoading && <Spinner className="submit-picks-loading" animation="border" />}
                                    </Form>
                                </Col>
                            </Row>}
                            {
                                !pageData.picksEnabled && <Row>
                                <Col>
                                    There currently is no active season underway. Check back soon for when picking will be enabled again.
                                </Col>
                            </Row>
                            }
                            { validationError.length > 0 && <Row className="mt-3">
                                <Col>                
                                    <Alert key="1" variant="danger">
                                        <strong>Hold up.</strong> {validationError} 
                                    </Alert>
                                </Col>
                            </Row>}
                        </Card.Body>
                    </Card> 
                </Col>
            </Row>     
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="bg-dark text-white">Past Picks</Card.Header>
                        <Card.Body>
                        {pageData.pastPicks.length > 0 && 
                            <>
                                <p>Below are all of the picks you have made for this season. To help us ensure accurate scores, it&apos;s recommended to every now and then verify scores as sometimes the National Climatic Data Center makes corrections after we entered the numbers into our system. If you come across any discrepancies, please contact us.</p>
                            
                                <Table striped bordered hover size="sm" responsive>
                                    <thead>
                                        <tr className="table-secondary">
                                            <th className='text-center'>Date</th>
                                            <th className='text-center'>Location</th>
                                            <th className='text-center'>Snow</th>
                                            <th className='text-center'>HDD</th>
                                            <th className='text-center'>Wind</th>
                                            <th className='text-center'>Days Out</th>
                                            <th className='text-center'>Raw Score</th>
                                            <th className='text-center'>Total Score</th>
                                            <th className='text-center'>Mulligan</th>                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {pageData.pastPicks.length > 0 ? pageData.pastPicks.map((x, index) => (
                                        <tr key={index}>
                                            <td className="text-center"><DateLink date={new Date(x.forecastDateString).toLocaleDateString()} /></td>
                                            <td className="text-center">
                                                <Link className="table-link" to={"/location/" + x.stationIdentifier + "/" + x.locationName} title={x.locationName}>{buildLocationNameCell(x)}</Link>   
                                            </td>
                                            <td className="text-center">{x.snow}&quot;</td>
                                            <td className="text-center">{x.wind}</td>
                                            <td className="text-center">{x.hdd}</td>
                                            <td className="text-center">{x.daysOut}</td>
                                            <td className="text-center">{x.rawScore}</td>
                                            <td className="text-center">{x.totalScore}</td>
                                            <td className="text-center">
                                                <PicksMulliganButton pick={x} showHandler={handleMulliganOpen} />         
                                            </td>
                                        </tr>
                                        ) ) : <tr><td colSpan="9">You currently don&apos;t have any past picks.</td></tr>}
                                    </tbody>
                                </Table>                              
                                <p>Please note, sometimes one or more score components (generally snow totals) are missing at the time scores are posted in the morning. Occasionally, it could be days until the missing number is in, so please be patient if a score has not yet been posted. Rest assured, we have backend systems in place reminding us of missing scores.</p>
                            </>}
                                {pageData.pastPicks.length === 0 && 
                                    <>
                                        You currently don&apos;t have any past picks for the current season.
                                    </>

                                }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={showRemovePickModal.visible} onHide={handleCloseRemovePickModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Please Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to remove your pick for {showRemovePickModal.location} on {showRemovePickModal.date}?</p>
                </Modal.Body>
                <Modal.Footer>
                    {showRemovePickModal.processing && <Spinner animation="border" />}
                    <Button variant="secondary" onClick={handleCloseRemovePickModal}>
                        Abort
                    </Button>
                    <Button variant="primary" onClick={removePickHandler}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showMulliganModal.visible} onHide={handleCloseMulliganModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Please Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to use your one and only mulligan on this pick for {showMulliganModal.location} on {showMulliganModal.date}?</p>
                </Modal.Body>
                <Modal.Footer>                    
                    {showMulliganModal.processing && <Spinner animation="border" />}
                    <Button variant="secondary" onClick={handleCloseMulliganModal}>
                        Abort
                    </Button>
                    <Button variant="primary" onClick={useMulliganHandler}>
                        Continue
                    </Button>                     
                </Modal.Footer>
            </Modal>
            </>}
        </>
    );
}

export default MyPicks;