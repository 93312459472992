import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SEO from '../Components/Seo'
import MissingScores from '../Components/MissingScores';

function MissingScoresPage(){
    return (
        <>
        <SEO 
            title="FantasyBlizzard.com -- Missing Scores" 
            description="Missing location scores in the current season" 
            crawl={true} 
        />
            <Row>
                <Col>
                    <h1>Missing Scores</h1>
                    <p>Below are all of the scores that are still missing. A missing score means one of the score&apos;s components was missing during the time window when the scoring machine runs, which is generally from 4am to 10am EST. If you notice every piece is now in, please let us know!</p>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <MissingScores />
                </Col>
            </Row>
        </>
    )
}

export default MissingScoresPage;