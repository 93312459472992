import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Form, Row, Table, Spinner} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link, useNavigate  } from "react-router-dom";
import LocationMap from '../Components/LocationMap';
import { faLink, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LocationDailyScoresGraph from '../Components/LocationDailyScoresGraph';
import SEO from '../Components/Seo';
import {determineCurrentObservationIcon} from '../Helper'
import { useWindowDimension } from '../Hooks/useWindowDimension';
import LocationLiveScoreLineGraph from '../Components/LocationLiveScoreLineGraph';

function Location(){

    const [width] = useWindowDimension();
    const [pageLoaded, setPageLoaded] = useState(false);
    const {stationID}  = useParams();
    const {id}  = useParams();
    const navigate = useNavigate();
    const [locationData, setLocationData] = useState({});

    useEffect(() => {

        fetch("/page/location/" + stationID + "/" + id)
            .then((response) => {   
                return response.json();
            })
            .then((data) => {
                loadData(data);
            })
            .catch(() => {
                
            })
            .finally(() => {
                setPageLoaded(true);
            });
    }, []);  

    function loadData(data){
        
        if(data.success){
            setLocationData(data);
        }
        else{
            navigate("/");
        }
    }

    function buildWeatherCell(liveScore){
        
        let currentWeatherIcon = determineCurrentObservationIcon(liveScore.currentWeatherIcon, liveScore.observation);
        let currentWeatherText = [];

        if(width >= 800){
            currentWeatherText =  [<span key={1}>{liveScore.observation.trim().length > 0 ? liveScore.observation : "Not available" }</span>, currentWeatherIcon];
        }
        else{
            currentWeatherText = currentWeatherIcon;
        }
 
        return currentWeatherText;
    }

    return (
        <>
            {!pageLoaded && <Row><Col className='text-center'><Spinner className='login-loading' animation="border" /></Col></Row>}
            {pageLoaded && <>
             <SEO 
                title={"FantasyBlizzard.com --" + locationData.location.name }
                description={"Current weather conditions for " + locationData.location.name }
                crawl={true} 
            />
            <Row className='mb-2'>
                <Col>
                    <h1>{id}</h1>
                </Col>
            </Row>   
            <Row className="mb-3" style={{ height: '410px' }}>
                <Col>
                    <LocationMap location={locationData.location} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Header className="bg-dark text-white">Location Details</Card.Header>
                        <Card.Body>
                            <Form>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="name">
                                        <Form.Label>Station ID</Form.Label>
                                        <Form.Control size="sm" value={locationData.location.stationIdentifier} disabled />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="rookieSeason">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control size="sm" value={locationData.location.name} disabled />
                                    </Form.Group>   

                                    <Form.Group as={Col} controlId="rookieSeason">
                                        <Form.Label>Time Zone</Form.Label>
                                        <Form.Control size="sm" value={locationData.location.timeZoneName} disabled />
                                    </Form.Group>                          
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="experience">
                                        <Form.Label>Latitude</Form.Label>
                                        <Form.Control size="sm" value={locationData.location.latitude + "°N"} disabled />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="location">
                                        <Form.Label>Longitude</Form.Label>
                                        <Form.Control size="sm" value={locationData.location.longitude+ "°W"} disabled />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="occupation">
                                        <Form.Label>Elevation</Form.Label>
                                        <Form.Control size="sm" value={locationData.location.elevation + " feet"}  disabled />
                                    </Form.Group>                            
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="experience">
                                        <Form.Label>Sunrise</Form.Label>
                                        <Form.Control size="sm" value={locationData.location.sunrise}  disabled />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="location">
                                        <Form.Label>Sunset</Form.Label>
                                        <Form.Control size="sm" value={locationData.location.sunset} disabled />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="occupation">
                                        <Form.Label>NWS Office</Form.Label> 
                                        <div className="form-link">
                                            <Link className="nws-link" to="http://www.weather.gov/" target="_blank">{locationData.location.nwsOfficeID}</Link>
                                            <FontAwesomeIcon icon={faLink} />
                                        </div>
                                    </Form.Group>
                                    
                                </Row>
                            </Form> 
                        </Card.Body>
                    </Card>                   
                </Col>                
            </Row> 
            <Row className="mb-3">
                <Col>                    
                    <LocationLiveScoreLineGraph locationID={locationData.location.id} />                        
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Header className="bg-dark text-white">Live Score</Card.Header>
                        <Card.Body>
                            <Table bordered hover size="sm" responsive>
                                <thead className="table-active">
                                    <tr>
                                        <th className="text-center align-middle">Observation</th>
                                        <th className="text-center align-middle">Wind</th>
                                        <th className="text-center align-middle">Temp</th>
                                        <th className="text-center align-middle">Visibility</th>
                                        <th className="text-center align-middle">Precip</th>
                                        <th className="text-center align-middle">Snow</th>
                                        <th className="text-center align-middle">Min</th>
                                        <th className="text-center align-middle">Max</th>
                                        <th className="text-center align-middle">HDD</th>
                                        <th className="text-center align-middle">Gust</th>
                                        <th className="text-center align-middle">Score</th> 
                                        <th className="text-center align-middle">METAR</th>                     
                                    </tr>
                                </thead>
                                <tbody>
                                    {locationData.liveScore != null ? <tr>
                                        <td className="text-center align-middle">{buildWeatherCell(locationData.liveScore)}</td>
                                        <td className="text-center align-middle">{locationData.liveScore.currentWind}</td>
                                        <td className="text-center align-middle">{locationData.liveScore.currentTemperature + "°F"}</td>
                                        <td className="text-center align-middle">{locationData.liveScore.currentVisibility + "mi"}</td>
                                        <td className="text-center align-middle">{locationData.liveScore.precipitation.toFixed(2) + "\""}</td>
                                        <td className="text-center align-middle">{locationData.liveScore.snow.toFixed(1) + "\""}</td>
                                        <td className="text-center align-middle">{locationData.liveScore.minTemp + "°F"}</td>
                                        <td className="text-center align-middle">{locationData.liveScore.maxTemp + "°F"}</td>
                                        <td className="text-center align-middle">{locationData.liveScore.hdd}</td>
                                        <td className="text-center align-middle">{locationData.liveScore.wind} mph</td>
                                        <td className="text-center align-middle">{locationData.liveScore.score}</td>
                                        <td className="text-center align-middle"><a className="table-link" href={`http://weather.rap.ucar.edu/surface/index.php?metarIds=k${locationData.liveScore.locationIdentifier}&hoursStr=past+24+hours`} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faMagnifyingGlass}/></a></td>
                                    </tr> : 
                                    <tr>
                                        <td colSpan="11">Live scores are not available for this location.</td>
                                    </tr> }                           
                                </tbody>                        
                            </Table>  
                            { locationData.liveScore != null ? <div className="text-right">
                                <small>Last updated: {locationData.liveScore.updatedString}</small>
                            </div> : <></>}  
                        </Card.Body>                        
                    </Card>                       
                </Col>    
            </Row>   
            <Row>
                <Col>
                    <LocationDailyScoresGraph locationID={locationData.location.id} />
                </Col>
            </Row>            
            </>}
        </>
    );
}

export default Location;