// https://react-chartjs-2.js.org/examples/line-chart/
import React, {useEffect, useState} from 'react';
import {Card, Spinner} from 'react-bootstrap'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

 function TopScoresGraph(props) {

  const [data, setData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [hasPicksForToday, setHasPicksForToday] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData(){
    
    fetch("/scores/player/today/top/" + props.count)
      .then((response) => {
          return response.json();
      })
      .then((response) => {
        if(response.success){
          setupChartDataObject(response.data);
        }
      })
      .catch(() => {
      })
      .finally(() => {
        setLoadingComplete(true);
      });
  }

  let options = {};

  function determineGraphColors(index){

    let borderColor = "";
    let backgroundColor = "";

    if(index === 0){
      borderColor = "rgb(255, 99, 132)";
      backgroundColor = "rgba(255, 99, 132)";
    }
    else if(index === 1){
      borderColor = 'rgb(53, 162, 235)';
      backgroundColor = 'rgba(53, 162, 235)';
    }
    else if(index === 2){
      borderColor = "rgb(124,252,0)";
      backgroundColor = "rgba(124,252,0)";
    }
    else if(index === 3){
      borderColor = "rgb(255, 165, 0)";
      backgroundColor = "rgba(255, 165, 0)";
    }
    else if(index === 4){
      borderColor = "rgb(0, 0, 0)";
      backgroundColor = "rgba(0, 0, 0)";
    }

    return {borderColor: borderColor, backgroundColor: backgroundColor}
  }

  function setupChartDataObject(data){
    
    if(data.players != null){

      const chartData = {
        labels: data.hours,
        datasets: data.players.map((player, index) => (
          {
            label: player.displayName,
            data: player.hourlyScores,
            ...determineGraphColors(index)
          }
        ))
      };

      options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: false,
            text: 'All times EDT',
          },
        },
        scales: {
          y: {
              min: 0,
              max: data.max < 100 ? 100 : undefined
          }
        }
      };

      setChartOptions(options);
      setData(chartData);
      setHasPicksForToday(true);
    }
    else{
      setHasPicksForToday(false);
    }   
  }

  return (
    <Card style={{ height: '420px' }}>
      <Card.Header className="bg-dark text-white">Today&apos;s Top 5 Player Scores By Hour</Card.Header>
      <Card.Body>
        {!loadingComplete && <Spinner className="card-body-loading" animation="border" />}
        {loadingComplete && hasPicksForToday && <><Line options={chartOptions} data={data} /> <span className="all-times-text">All times eastern</span></> }   
        {loadingComplete && !hasPicksForToday && <p>There are no picks for today.</p>}  
      </Card.Body>
    </Card>
  );
}

export default TopScoresGraph;
