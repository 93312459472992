import React from 'react';
import {Spinner} from 'react-bootstrap';

function Loading() {

    return (
        <div className="loading-container text-center">
            <Spinner animation="border" />
        </div>
    )
}

export default Loading;