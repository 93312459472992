import {Card, Col, Row, Spinner, Table } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import LocationLink from './LocationLink';
import ProfileLink from './ProfileLink';
import DateLink from './DateLink';

function MissingScores(){

    const [loadComplete, setLoadComplete] = useState(false);
    const [rowsContent, setRowsContent] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData(){
        
        fetch("/scores/missing")
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            loadTable(data);
        })
        .catch(() => {
            
        })
        .finally(() => {
            setLoadComplete(true);
        });
    }

    function loadTable (response){

        if(response.success){
            
            let rows = [];

            if(response.data.length > 0){
                rows = response.data.map((x, index) => (
                    <tr key={index}>
                        <td className="text-center align-middle"><DateLink date={new Date(x.date).toLocaleDateString()} /></td>
                        <td className="text-center align-middle">{x.stationID}</td>
                        <td className="text-center align-middle"><LocationLink stationIdentifier={x.stationID} locationName={x.locationName} maxLength="100" /></td>
                        <td className="text-center align-middle">{x.displayNames.map((name, index) => 
                            <><ProfileLink key={index} name={name.trim()} maxLength="20" />
                            {index + 1 < x.displayNames.length ? ", " : <></>}
                            </>  
                            )}</td>
                        <td className="text-center align-middle"><a className="table-link" href={"https://www.weather.gov/wrh/climate?wfo=" + x.nwsOfficeID} target="_blank" rel="noreferrer" ><FontAwesomeIcon icon={faMagnifyingGlass} /></a></td>
                    </tr>
                ));
            }
            else{
                rows = [<tr key={1}><td colSpan="5">There are currently no missing scores</td></tr>];
            }

            setRowsContent(rows);
        }
    }

    return (
        <>
        {!loadComplete && <Row><Col className='text-center'><Spinner className='login-loading' animation="border" /></Col></Row>}
        {loadComplete && <>
        <Row className='mb-3'>
            <Col>
                <Card>
                    <Card.Header className="bg-dark text-white">Missing Scores</Card.Header>
                    <Card.Body>
                        <Table striped bordered hover size="sm" responsive>
                            <thead className="table-active">
                                <tr>   
                                    <th className="text-center align-middle">Date</th>
                                    <th className="text-center align-middle">Station ID</th>  
                                    <th className="text-center align-middle">Location</th>  
                                    <th className="text-center align-middle">Impacted Players</th>  
                                    <th className="text-center align-middle">Climate Data</th>                      
                                </tr>
                            </thead>
                            <tbody>
                                {rowsContent}
                            </tbody>
                        </Table> 
                    </Card.Body>                    
                </Card>                     
            </Col>
        </Row>   
        </>
        }   
        </>
    );
}

export default MissingScores;