//https://betterprogramming.pub/authenticating-frontend-apps-using-cookies-in-net-core-web-api-2df311e735bb

import React from 'react';
import { useRef, useState, useContext } from 'react';
import {Alert, Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../App';
import { useNavigate  } from "react-router-dom";
import Cookies from 'js-cookie';
import SEO from '../Components/Seo';

function Login(){

    const navigate = useNavigate();
    const { setIsLoggedIn } = useContext(AuthContext);
    const { setLevel } = useContext(AuthContext);  
    const emailAddressRef = useRef();
    const passwordRef = useRef();
    const [validationError, setValidationError] = useState("");
    const [sendingForm, setSendingForm] = useState(false);    

    const action = localStorage.getItem("action"); 
    const displayRegisteredMessage = action === "registered";
    localStorage.removeItem("action");
    
    setIsLoggedIn(false);
    setLevel(0);

    Cookies.remove('.AspNetCore.Cookies', { path: '' });

    const submitHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const errors = checkForm();

        if(errors.length === 0){

            setSendingForm(true);

            var body = {
                username: emailAddressRef.current.value,
                password: passwordRef.current.value
            }

            fetch("auth/login", {
                method: "POST", 
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
              })
                .then((response) => {
                    
                    if (response.status !== 200) {
                        setValidationError("Login failed. Please check your credentials and try again.");
                    }          

                    return response.json();
                })
                .then((data) => {

                    if(data.level > 0)
                    {
                        setIsLoggedIn(true);
                        navigate("/my-picks");

                        setLevel(data.level);
                    }
                    else{
                        setValidationError("Login failed. Please check your credentials and try again.");
                    }                    
                })
                .catch(() => {
                    
                })
                .finally(() => {
                    setSendingForm(false);
                });    
        }
    }

    function checkForm(){

        let error = "";        

        if(emailAddressRef.current.value.trim().length === 0){
            error = "You forgot to enter an email address.";
        }
        else if(passwordRef.current.value.trim().length === 0){
            error = "You forgot to enter a password.";
        }       

        setValidationError(error);

        return error;
    }

    return (
        <>
            <SEO 
                title="FantasyBlizzard.com -- Login"
                description="Log into your account to play Fantasy Blizzard"
                crawl={true} 
            />
            <Row>
                <Col>
                    <h1>Login</h1>
                </Col>
            </Row>
            { displayRegisteredMessage > 0 && <Row className="mt-3">
                <Col>                
                    <Alert key="1" variant="success">
                        Thanks for registering! Please login to continue.
                    </Alert>
                </Col>
            </Row>}
            <Row>
                <Col>
                    <Form>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" ref={emailAddressRef} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" ref={passwordRef} />
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={submitHandler}>
                            Login
                        </Button>     
                        { sendingForm && <Spinner className='login-loading' animation="border" />}                 
                    </Form>  
                </Col>
            </Row>
            <Row>
                <Col>
                    <br />
                    <span className="ml-3">Forgot Password? <Link to="/reset-password">Click here</Link> to reset your password.</span>
                </Col>
            </Row>
            { validationError.length > 0 && <Row className="mt-3">
                <Col>                
                    <Alert key="1" variant="danger">
                        {validationError} 
                    </Alert>
                </Col>
            </Row>}
        </>
    );
}

export default Login;