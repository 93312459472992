import React from "react";
import {Alert, Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useRef, useState, useEffect } from 'react';
import { useNavigate  } from "react-router-dom";
import SEO from '../Components/Seo';

function MyAccount() {

    const currentPasswordRef = useRef();
    const passwordRef = useRef();
    const password2Ref = useRef();
    const nameRef = useRef();
    const displayNameRef = useRef();
    const experienceRef = useRef();
    const locationRef = useRef();
    const occupationRef = useRef();
    const twitterRef = useRef();
    const interestsRef = useRef();
    const bioRef = useRef();


    const navigate = useNavigate();
    const [loadedDateTime, setLoadedDateTime] = useState(new Date());
    const [sendingUpdatePasswordForm, setSendingUpdatePasswordForm] = useState(false);  
    const [passwordUpdateSuccess, setPasswordUpdateSuccess] = useState(false);  
    const [updateBioSuccess, setUpdateBioSuccess] = useState(false);  
    const [sendingUpdateProfile, setSendingUpdateProfile] = useState(false);  
    const [bioValidationError, setBioValidationError] = useState(false); 
    const [reactivatingUser, setReactivatingUser] = useState(false);  

    const [passwordValidationError, setPasswordValidationError] = useState("");
    const [pageLoaded, setPageLoaded] = useState(false);
    const [name, setName] = useState();
    const [displayName, setDisplayName] = useState();
    const [experienceID, setExperienceID] = useState();
    const [location, setLocation] = useState();
    const [occupation, setOccupation] = useState();
    const [twitter, setTwitter] = useState();
    const [interests, setInterests] = useState();
    const [bio, setBio] = useState();
    const [isActiveForSeason, setIsActiveForSeason] = useState(true);

    useEffect(() => {

        fetch("page/my-account")
            .then((response) => {

                if (response.status === 401) {
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                loadData(data);
            })
            .catch(() => {
                
            })
            .finally(() => {

            });
    }, [loadedDateTime]);  

    function updatePasswordHandler(e){
        e.preventDefault();
        e.stopPropagation();

        setPasswordUpdateSuccess(false);
        setUpdateBioSuccess(false);        

        const errors = checkPasswordForm();
  
        if(!errors){

            setSendingUpdatePasswordForm(true);            

            var body = {
                currentPassword: currentPasswordRef.current.value.trim(),
                newPassword: passwordRef.current.value.trim()
            }

            fetch("auth/updatepassword", {
                method: "POST", 
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
              })
            .then((response) => {

                if (response.status === 401) {
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {

                if(data.success){
                    setPasswordUpdateSuccess(true);
                }
                else{
                    setPasswordValidationError(data.message);
                }                
            })
            .catch(() => {
                
            })
            .finally(() => {
                setSendingUpdatePasswordForm(false);
            });
        }
    }

    function loadData(data){

        setName(data.name);
        setDisplayName(data.displayName);
        setExperienceID(data.experienceID);
        setLocation(data.location);
        setOccupation(data.occupation);
        setTwitter(data.twitter);
        setInterests(data.interests);
        setBio(data.bio);
        setIsActiveForSeason(data.isActiveForSeason);

        setPageLoaded(true);
    }

    function updateProfileHandler(e){

        setBioValidationError("");
        setPasswordUpdateSuccess(false);
        setUpdateBioSuccess(false);

        e.preventDefault();
        e.stopPropagation();

        if(displayNameRef.current.value.trim().length > 0){
            setSendingUpdateProfile(true);            

            const body = {                
                DisplayName: displayNameRef.current.value.trim(),
                Name: nameRef.current.value.trim(),        
                Experience: experienceRef.current.value,        
                Location: locationRef.current.value.trim(),        
                Occupation: occupationRef.current.value.trim(),        
                Twitter: twitterRef.current.value.trim(),        
                Interests: interestsRef.current.value.trim(),        
                Bio: bioRef.current.value.trim()
            }

            fetch("user/update", {
                method: "POST", 
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
              })
            .then((response) => {

                if (response.status === 401) {
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                if(data.success){
                    setUpdateBioSuccess(true);
                }
                else{
                    setBioValidationError(data.message);
                }              
            })
            .catch(() => {
                
            })
            .finally(() => {
                setSendingUpdateProfile(false);
            });        
        }
        else{
            setBioValidationError("Please enter a Display Name");
        }
    }

    function checkPasswordForm(){

        let error = "";  

        if(passwordRef.current.value.trim() !== password2Ref.current.value.trim()){
            error = "Your passwords don't match.";
        }
        else if(passwordRef.current.value.trim().length === 0 || currentPasswordRef.current.value.trim().length === 0){
            error = "You forgot to enter a password.";
        }
        else if(passwordRef.current.value.trim().length < 6){
            error = "All we need is at least 6 characters for your password.";
        }

        setPasswordValidationError(error);

        return error.length !== 0;
    }

    function reactivateHandler(){

        setReactivatingUser(true);

        var body = {
            name: nameRef.current.value.trim()
        }

        fetch("user/reactivate", {
            method: "POST", 
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
          })
        .then((response) => {

            if (response.status === 401) {
                navigate("/login");
            }
        })
        .catch(() => {
            
        })
        .finally(() => {
            setReactivatingUser(false);
            setLoadedDateTime(new Date());
        });        
    }

    return (
        <>
            {!pageLoaded && <Row><Col className='text-center'><Spinner className='login-loading' animation="border" /></Col></Row>}
            {pageLoaded && <>
            <SEO 
                title="FantasyBlizzard.com -- My Account"
                description="My account for Fantasy Blizzard. Manage your here"
                crawl={false} 
            />
            <h1>My Account</h1>
            <Row className="mb-3">
                <Col sm="12">
                    <strong>Account Status:</strong> {isActiveForSeason ? "Active" : "Inactive"} 
                </Col>
                {
                    !isActiveForSeason && 
                    <Col sm="12" className="mt-3">
                        <Alert key="1" variant="warning">
                            <strong>Activate For Upcoming Season</strong>
                            <p>Your account is currently not active for the upcoming or current season. If you&apos;re ready to play, please click the button below.</p>
                            <Button onClick={reactivateHandler}>Reactivate Me</Button> { reactivatingUser && <Spinner className='login-loading mr-3' animation="border" />} 
                        </Alert>
                    </Col>
                }
            </Row>
            <Row className="mb-3">
                <Col>
                    <Card>
                        <Card.Header className="bg-dark text-white">Change Password</Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Current Password</Form.Label>
                                    <Form.Control type="password" ref={currentPasswordRef} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" minLength={6} ref={passwordRef} />
                                    <Form.Text className="text-muted">
                                        Anything 6 characters or more is good for us.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="password2">
                                    <Form.Label>Repeat Password</Form.Label>
                                    <Form.Control type="password" minLength={6} ref={password2Ref} />
                                </Form.Group>

                                <div className="text-right">
                                { sendingUpdatePasswordForm && <Spinner className='login-loading mr-3' animation="border" />}   
                                    <Button variant="primary" type="submit" onClick={updatePasswordHandler}>
                                        Update Password
                                    </Button>  
                                </div>              
                            </Form>  
                            { passwordValidationError.length > 0 && <Row className="mt-3">
                                <Col>                
                                    <Alert key="1" variant="danger">
                                        {passwordValidationError} 
                                    </Alert>
                                </Col>
                            </Row>}
                            { passwordUpdateSuccess > 0 && <Row className="mt-3">
                                <Col>                
                                    <Alert key="1" variant="success">
                                        Your password was successfully updated
                                    </Alert>
                                </Col>
                            </Row>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>                        
                        <Card.Header className="bg-dark text-white">Edit Profile</Card.Header>
                        <Card.Body>
                            <p>All fields optional, except for Display Name.</p>

                            <Form>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" ref={nameRef} defaultValue={name} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="displayName">
                                    <Form.Label>Display Name</Form.Label>
                                    <Form.Control type="text" ref={displayNameRef} defaultValue={displayName} />
                                    <Form.Text className="text-muted">
                                    Please keep your display name rated PG-13 or lower and changes to a minimum during the season. Any inappropriate names will be subject to administrative changes without notice. Repeat violators may have their ability to change their name revoked.
                                    </Form.Text>
                                </Form.Group>
                            
                                <Form.Group className="mb-3" controlId="knowledge">
                                    <Form.Label>Knowledge</Form.Label> 
                                    <Form.Select ref={experienceRef} defaultValue={experienceID}>
                                        <option value="1">Professional Meteorologist</option>
                                        <option value="2">Meteorology Student</option>
                                        <option value="3">Weather Enthusiast</option>                        
                                        <option value="5">Novice</option>
                                        <option value="4">Other</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="location">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control type="text" ref={locationRef} defaultValue={location} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="occupation">
                                    <Form.Label>Occupation</Form.Label>
                                    <Form.Control type="text" ref={occupationRef} defaultValue={occupation} />
                                </Form.Group>       

                                <Form.Group className="mb-3" controlId="twitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text" ref={twitterRef} defaultValue={twitter} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="interests">
                                    <Form.Label>Interests</Form.Label>
                                    <Form.Control type="text" ref={interestsRef} defaultValue={interests} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="bio">
                                    <Form.Label>Bio</Form.Label>
                                    <Form.Control as="textarea" rows={3} ref={bioRef} defaultValue={bio}  />
                                </Form.Group>

                                <div className="text-right">
                                    { sendingUpdateProfile && <Spinner className='login-loading mr-3' animation="border" />} 
                                    <Button variant="primary" type="submit" onClick={updateProfileHandler}>Update Profile</Button>     
                                </div>                          
                            </Form>
                            { bioValidationError.length > 0 && <Row className="mt-3">
                                <Col>                
                                    <Alert key="1" variant="danger">
                                        {bioValidationError}
                                    </Alert>
                                </Col>
                            </Row>}
                            { updateBioSuccess > 0 && <Row className="mt-3">
                                <Col>                
                                    <Alert key="1" variant="success">
                                        Your bio was successfully updated
                                    </Alert>
                                </Col>
                            </Row>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </>}
        </>
    );
}

export default MyAccount;