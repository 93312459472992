import React, {useState} from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './Page/Home';
import About from './Page/About';
import Contact from './Page/Contact';
import MainNav from './Layout/MainNav';
import { Container } from 'react-bootstrap';
import Footer from './Layout/Footer';
import Standings from './Page/Standings';
import DivisionalStandings from './Page/DivisionalStandings';
import LiveScores from './Page/Live-Scores';
import Register from './Page/Register';
import Login from './Page/Login';
import MyPicks from './Page/MyPicks';
import ResetPassword from './Page/ResetPassword';
import Logout from './Page/Logout';
import MyAccount from './Page/MyAccount';
import Profile from './Page/Profile';
import Location from './Page/Location';
import Scoreboard from './Page/Scoreboard';
import NotFound from './Page/NotFound';
import AdminServices from './Page/AdminServices';
import AdminAddScores from './Page/AdminAddScores';
import AdminUsers from './Page/AdminUsers';
import { HelmetProvider } from 'react-helmet-async';
import MissingScoresPage from './Page/MissingScores';
import AdminManagement from './Page/AdminManagement';
import YesterdayScores from './Page/YesterdayScores';
import PastSeasons from './Page/PastSeasons';

export const AuthContext = React.createContext(false);

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [level, setLevel] = useState(0);
  const helmetContext = {};

  return (
    <>
      <AuthContext.Provider value={{ isLoggedIn: isLoggedIn, setIsLoggedIn: setIsLoggedIn, level: level, setLevel: setLevel }}>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <MainNav />
          <Container>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/full-standings" element={<Standings />} />
              <Route path="/my-picks" element={<MyPicks />} />
              <Route path="/divisional-standings" element={<DivisionalStandings />} />
              <Route path="/scoreboard" element={<Scoreboard />} />
              <Route path="/live-scores" element={<LiveScores />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/missing-scores" element={<MissingScoresPage />} />
              <Route path="/yesterday-scores" element={<YesterdayScores />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/past-seasons" element={<PastSeasons />} />
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/profile/:id" element={<Profile />} />
              <Route path="/location/:stationID/:id" element={<Location />} />
              <Route path="/admin/services" element={<AdminServices />} />
              <Route path="/admin/scores" element={<AdminAddScores />} />
              <Route path="/admin/users" element={<AdminUsers />} />
              <Route path="/admin/management" element={<AdminManagement />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
          <Footer />
        </BrowserRouter>
      </HelmetProvider>
      </AuthContext.Provider>
    </>
  );
}

export default App;