import React, {useEffect, useState} from 'react';
import {Card, Spinner} from 'react-bootstrap'
import {  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function TopColdestLocationsGraph(props) {

  const [data, setData] = useState({});
  const [rawData, setRawData] = useState({});
  const [loadingComplete, setLoadingComplete] = useState(false);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: false,
        text: 'Ordered by HDD',
        position: "bottom"
      },
      tooltip: {
        callbacks: {     
          label: (yDatapoint) => {
            return  yDatapoint.dataset.label + " " + yDatapoint.raw + "°F"
          },
          title: (xDatapoint) => {
            const item = rawData.filter(x => x.index === xDatapoint[0].dataIndex);
 
            if(item.length === 1){
              return item[0].locationName + " HDD: " +  item[0].hdd;
            }
            return xDatapoint.raw;
          }
        }
      }
    },  
    scales: {y: { title: { display: true, text: '°F' }}}
  };

  useEffect(() => {
    if(props.data === null || props.data.length === 0){
      //fetchData();
    }
    else{      
      const sortedData = sortData(props.data);
      setupChartDataObject(sortedData);
    }
  }, [props]);

  // function fetchData(){
    
  //   fetch("scores/live/top-location-scores/" + props.count)
  //     .then((response) => {
  //         return response.json();
  //     })
  //     .then((response) => {
  //       if(response.success){
  //         const sortedData = sortData(response.data);
  //        setupChartDataObject(sortedData);
  //       }
  //       else{
  //         setupChartDataObject(props.data)
  //       }
  //     })
  //     .catch((err) => {
  //     })
  //     .finally(() => {
  //       setLoadingComplete(true);
  //     });
  // }

  function sortData(data){
    
    const sortedData = data.sort(function (a, b){
    if (a.hdd > b.hdd) {
        return -1;
    }
    else if (a.hdd < b.hdd) {
        return 1;
    }    
    else if(b.locationIdentifier - a.locationIdentifier < 0){
      return -1;
    }
    else{
      return 1;
    }
    
    }).slice(0, props.count);

    return sortedData;
  }

  function setupChartDataObject(locationData){
    locationData = locationData.slice(0, props.count);
    const locations = locationData.map(x => x.locationIdentifier);
    
    const data = {
      labels: locations,
      datasets: [
        {
          label: 'Max Temp',
          data: locationData.map(x => x.maxTemp),
          backgroundColor: 'rgba(255, 99, 132)',
        },
        {
          label: 'Min Temp',
          data: locationData.map(x => x.minTemp),
          backgroundColor: 'rgba(53, 162, 235)',
        },
      ]
    };

    locationData = locationData.map((obj, index) => ({ ...obj, index: index }))

    setData(data);
    setRawData(locationData);
    setLoadingComplete(true);
  }

  return (
    <Card style={{ height: '420px' }}>
      <Card.Header className="bg-dark text-white">Today&apos;s Top {props.count} Coldest Locations</Card.Header>
      <Card.Body>
        {!loadingComplete && <Spinner className="card-body-loading" animation="border" />}
        {loadingComplete && <Bar options={options} data={data} /> } 
      </Card.Body>
    </Card>
  );
}

export default TopColdestLocationsGraph;