import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate  } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react';
import MissingScores from '../Components/MissingScores';
import SEO from '../Components/Seo';

function AdminAddScores(){

    const yearRef = useRef();
    const monthRef = useRef();
    const dayRef = useRef();
    const locationRef = useRef();
    const snowRef = useRef();
    const hddRef = useRef();
    const windRef = useRef();

    const userRef = useRef();
    const yearRef2 = useRef();
    const monthRef2 = useRef();
    const dayRef2 = useRef();
    const locationRef2 = useRef();

    const [locations, setLocations] = useState([]);
    const [users, setUsers] = useState([]);
    const [addScoreError, setAddScoresError] = useState("");
    const [addPickError, setAddPickError] = useState("");
    const [reloadMissingScores, setReloadMissingScores] = useState(0);
    const [sendingAddPickForm, setSendingAddPickForm] = useState(false);  
    const [sendingAddScoreForm, setSendingAddScoreForm] = useState(false);  

    const navigate = useNavigate();

    const currentDate = new Date();
    const years = [currentDate.getFullYear(), currentDate.getFullYear() - 1];
    
    const days = []
    for(var day = 1; day <= 31; day++){
        days.push(day);
    }

    useEffect(() => {
        fetchLocations();
        fetchUsers();
    }, []);

    function fetchLocations(){
        
        fetch("/locations/active")
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            if(response.success){
                setLocations(response.data);
            }
        })
        .catch(() => {
            
        })
        .finally(() => {

        });
    }

    function fetchUsers(){
        fetch("/adminUtil/users/active")
        .then((response) => {

            if(response.status !== 200){
                navigate("/login");
            } 

            return response.json();
        })
        .then((response) => {
            if(response.success){
                setUsers(response.data);
            }
        })
        .catch(() => {
            
        })
        .finally(() => {

        });
    }

    function addScoreHandler(e){
       
        e.preventDefault();

        const errors = checkScoresForm();

        if(errors.length === 0){

            setSendingAddScoreForm(true);
            
            var body = {
                year: yearRef.current.value,
                month: monthRef.current.value,
                day: dayRef.current.value,
                locationID: locationRef.current.value,
                snow: snowRef.current.value,
                hdd: hddRef.current.value,
                wind: windRef.current.value
            }

            fetch("/adminUtil/score/add", {
                method: "POST", 
                headers: {
                "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            })
            .then((response) => {

                if(response.status !== 200){
                    navigate("/login");
                }  

                return response.json();
            })
            .then((response) => {    
                if(response.success){
                    yearRef.current.value = currentDate.getFullYear();
                    monthRef.current.value = currentDate.getMonth() + 1;
                    dayRef.current.value = currentDate.getDate();
                    locationRef.current.value = "0";
                    snowRef.current.value = "0";
                    hddRef.current.value = "0";
                    windRef.current.value = "0";

                    setReloadMissingScores(() => reloadMissingScores + 1);
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setSendingAddScoreForm(false);
            });
        }
    }

    function addPickHandler(e){
        e.preventDefault();

        const errors = checkAddPickForm();

        if(errors.length === 0){

            setSendingAddPickForm(true);
            
            var body = {
                year: yearRef2.current.value,
                month: monthRef2.current.value,
                day: dayRef2.current.value,
                locationID: locationRef2.current.value,
                userID: userRef.current.value
            }

            fetch("/adminUtil/pick/add", {
                method: "POST", 
                headers: {
                "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            })
            .then((response) => {

                if(response.status !== 200){
                    navigate("/login");
                }  

                return response.json();
            })
            .then((response) => {    
                if(response.success){
                    yearRef2.current.value = currentDate.getFullYear();
                    monthRef2.current.value = currentDate.getMonth() + 1;
                    dayRef2.current.value = currentDate.getDate();
                    locationRef2.current.value = "0";
                    userRef.current.value = "0";
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setSendingAddPickForm(false);
            });
        }
    }

    function checkScoresForm(){

        var errors = "";

        if(locationRef.current.value === "0"){
            errors = "Please select a location"
        }

        setAddScoresError(errors);

        return errors;
    }

    function checkAddPickForm(){

        var errors = "";

        if(userRef.current.value === "0"){
            errors = "Please select a User"
        }
        else if(locationRef2.current.value === "0"){
            errors = "Please select a location"
        }

        setAddPickError(errors);

        return errors;
    }

    return (
        <>
            <SEO 
                title="FantasyBlizzard.com -- Add Scores" 
                description="" 
                crawl={false} 
            />
            <Row>
                <Col><h1>Add Scores</h1></Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <MissingScores key={reloadMissingScores} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                <Card>
                    <Card.Header className="bg-dark text-white">Add Scores</Card.Header>
                        <Card.Body>
                            <Form onSubmit={addScoreHandler}>
                                <Form.Group className="mb-3">                                    
                                    <Form.Label>Date</Form.Label>
                                    <Form.Select defaultValue={currentDate.getMonth() + 1} ref={monthRef}>
                                        <option value="1">January</option>
                                        <option value="2">February</option>
                                        <option value="3">March</option>
                                        <option value="4">April</option>
                                        <option value="5">May</option>
                                        <option value="6">June</option>
                                        <option value="7">July</option>
                                        <option value="8">August</option>
                                        <option value="9">September</option>
                                        <option value="10">October</option>        
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </Form.Select>
                                    <Form.Select defaultValue={currentDate.getDate() - 1} ref={dayRef}>
                                        {days.map((x, index) => (
                                            <option key={index} value={x}>{x}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Select defaultValue={currentDate.getFullYear()} ref={yearRef}>
                                        {years.map((x, index) => (
                                            <option key={index} value={x}>{x}</option>
                                        ))}
                                    </Form.Select>
                                    
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="pickLocation">
                                    <Form.Label>Location</Form.Label>                                    
                                    <Form.Select ref={locationRef}>
                                        <option  key="-1" value="0">Select</option>
                                        {locations.map((x, index) => (
                                            <option key={index} value={x.id}>{x.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Snow</Form.Label>
                                    <Form.Control type="text" defaultValue="0" ref={snowRef} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>HDD</Form.Label>
                                    <Form.Control type="number" defaultValue="0" ref={hddRef} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Wind</Form.Label>
                                    <Form.Control type="number" defaultValue="0" ref={windRef} />
                                </Form.Group>

                                <Button variant="primary" type="submit">Add Score</Button>
                                { sendingAddScoreForm && <Spinner className='login-loading' animation="border" />}  
                                {addScoreError.length > 0 && <Row className="mt-3">
                                    <Col>
                                        <Alert key="1" variant="danger">
                                            <strong>{addScoreError}</strong>
                                        </Alert>
                                    </Col>
                                </Row> }
                            </Form>  
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                <Card>
                    <Card.Header className="bg-dark text-white">Add Pick</Card.Header>
                        <Card.Body>
                            <Form onSubmit={addPickHandler}>
                                <Form.Group className="mb-3">
                                    <Form.Label>User</Form.Label>                                    
                                    <Form.Select ref={userRef}>
                                        <option key="0" value="0">Select</option>
                                        {users.map((x, index) => (
                                            <option key={index} value={x.id}>{x.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Select defaultValue={currentDate.getMonth() + 1} ref={monthRef2}>
                                        <option value="1">January</option>
                                        <option value="2">February</option>
                                        <option value="3">March</option>
                                        <option value="4">April</option>
                                        <option value="5">May</option>
                                        <option value="6">June</option>
                                        <option value="7">July</option>
                                        <option value="8">August</option>
                                        <option value="9">September</option>
                                        <option value="10">October</option>        
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </Form.Select>
                                    <Form.Select defaultValue={currentDate.getDate()} ref={dayRef2}>
                                        {days.map((x, index) => (
                                            <option key={index} value={x}>{x}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Select defaultValue={currentDate.getFullYear()} ref={yearRef2}>
                                        {years.map((x, index) => (
                                            <option key={index} value={x}>{x}</option>
                                        ))}
                                    </Form.Select>                                   
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Location</Form.Label>                                    
                                    <Form.Select ref={locationRef2}>
                                        <option  key="-1" value="0">Select</option>
                                        {locations.map((x, index) => (
                                            <option key={index} value={x.id}>{x.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>                                

                                <Button variant="primary" type="submit">Add Pick</Button>
                                { sendingAddPickForm && <Spinner className='login-loading' animation="border" />}  
                                {addPickError.length > 0 && <Row className="mt-3">
                                    <Col>
                                        <Alert key="1" variant="danger">
                                            <strong>{addPickError}</strong>
                                        </Alert>
                                    </Col>
                                </Row>  }                             
                            </Form>  
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AdminAddScores;