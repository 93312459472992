import React from 'react';
import {Alert} from 'react-bootstrap';

function MyPicksAlertMessage(props){

    return (
    <>
        {props.message &&
        <Alert key="1" variant={props.variant}>                        
            {props.message}
        </Alert> }
    </>)
}

export default MyPicksAlertMessage;