import React from 'react';
import {Alert, Button, Col, Form, Modal, Row, Spinner} from 'react-bootstrap';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { useNavigate  } from "react-router-dom";
import SEO from '../Components/Seo';

function Register(){

    const emailAddressRef = useRef();
    const passwordRef = useRef();
    const password2Ref = useRef();
    const displayNameRef = useRef();
    const experienceRef = useRef();
    const [validationError, setValidationError] = useState("");
    const [sendingForm, setSendingForm] = useState(false);    
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const submitHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
       const success = checkForm();

        if(success){
            setSendingForm(true);

            var body = {
                username: emailAddressRef.current.value,
                password: passwordRef.current.value,
                displayName: displayNameRef.current.value,
                experienceID: experienceRef.current.value
            }

            fetch("auth/register", {
                method: "POST", 
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
              })
                .then((response) => {
    
                    if (!response.ok) {
                        setValidationError(response.statusText);
                    }
                    return response.json();
                })
                .then((data) => {
                    registerResponse(data);
                })
                .catch(() => {
                })
                .finally(() => {
                    setSendingForm(false);
                });
        }
    }

    function registerResponse(data){
        if(!Object.prototype.hasOwnProperty.call(data, "errors") ) // !data.hasOwnProperty("errors")
        {
            localStorage.setItem("action", "registered");
            navigate("/login");         
        }
        else{
            setValidationError(data.errors[0]); 
        }
    }

    function checkForm(){

        let error = "";        

        if(emailAddressRef.current.value.trim().length === 0){
            error = "You forgot to enter an email address.";
        }
        else if(passwordRef.current.value.trim() !== password2Ref.current.value.trim()){
            error = "Your passwords don't match.";
        }
        else if(passwordRef.current.value.trim().length === 0){
            error = "You forgot to enter a password.";
        }
        else if(passwordRef.current.value.trim().length < 6){
            error = "All we need is at least 6 characters for your password.";
        }
        else if(displayNameRef.current.value.trim().length === 0){
            error = "How about a display name?";
        }
        else if(experienceRef.current.value === "0"){
            error = "Don't be shy, what's your level of knowledge?";
        }

        setValidationError(error);

        return error.length === 0;
    }

    return (
        <>
            <SEO 
                title="FantasyBlizzard.com -- Register"
                description="Register and sign up to play Fantasy Blizzard"
                crawl={true} 
            />
            <h1>Register</h1>
            <p>Are you ready to give FantasyBlizzard a go? Use the form below to sign up for FREE!</p>
            <Form>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" ref={emailAddressRef} />
                    <Form.Text className="text-muted">
                        This will be your login.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" minLength={6} ref={passwordRef} />
                    <Form.Text className="text-muted">
                        This isn&apos;t a new bank account. Anything 6 characters or more is good for us.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="password2">
                    <Form.Label>Repeat Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" minLength={6} ref={password2Ref} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="displayName">
                    <Form.Label>Display Name</Form.Label>
                    <Form.Control type="text" placeholder="Display Name" ref={displayNameRef} />
                    <Form.Text className="text-muted">
                        This is what will be displayed throughout the website. Please keep it rated PG 13 or below. Offensive names are subject to change without notice.
                    </Form.Text>
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="knowledge">
                    <Form.Label>Knowledge</Form.Label> <FontAwesomeIcon className="pointer" icon={faCircleQuestion} onClick={handleShow} />
                    <Form.Select ref={experienceRef}>
                        <option value="0">Select</option>
                        <option value="1">Professional Meteorologist</option>
                        <option value="2">Meteorology Student</option>
                        <option value="3">Weather Enthusiast</option>                        
                        <option value="5">Novice</option>
                        <option value="4">Other</option>
                    </Form.Select>
                </Form.Group>

                <Button variant="primary" type="submit" onClick={submitHandler}>
                    Sign me up
                </Button>
            </Form>
            { validationError.length > 0 && <Row className="mt-3">
                <Col>                
                    <Alert key="1" variant="danger">
                        {validationError} 
                    </Alert>
                </Col>
            </Row>}

            { sendingForm && <Row className="mt-3">
                <Col>                
                    <Spinner animation="border" />
                </Col>
            </Row>}

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Knowledge</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>What is your level of knowledge? This is used to group players into different divisions. If you&apos;d rather not say, just select other.</p>

                    <strong>Professional Meteorologist</strong>: You have a degree in meteorology/related field and/or have meteorologist somewhere in your job title.<br /><br />
                    <strong>Meteorology Student</strong>: You are currently studying meteorology/related field.<br /><br />
                    <strong>Weather Enthusiast</strong>: You are just a fan of weather and don&apos;t have any high level education in meteorology, but still probably knows the difference between hail and graupel.<br /><br />
                    <strong>Novice</strong>: You really don&apos;t know a whole lot about weather in general, but enjoy compitition.<br /><br />
                    <strong>Other</strong>: Something else or prefer not to reveal your experience.
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
    </>
    )
}

export default Register;