import React, { useEffect, useState } from 'react';
import Standing from '../Components/Standing';
import {Card } from 'react-bootstrap';
import SEO from '../Components/Seo';
import Loading from '../Components/Loading';

function DivisionalStandings(){

    const [, setStandings] = useState([]);
    const [content, setContent] = useState();

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {

        setContent(<Loading text="Loading picks" />);

        fetch("standings/full")
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                loadStandings(data);
            })
            .catch(() => {

            })
            .finally(() => {

            });
    } 

    const divisions = [
        { name: "Professional Meteorologist", key: 1 },
        { name: "Meteorology Student", key: 2 },
        { name: "Weather Enthusiast", key: 3 },
        { name: "Other", key: 4 },
        { name: "Novice", key: 5 }
    ];

    function loadStandings(data) {

        let content = [];

        divisions.forEach(function (division) {
 
            const standingsForDivision = data.filter(x => x.divisionID === division.key);

            content.push(<Card><Card.Header className="bg-dark text-white">{division.name }</Card.Header><Card.Body><Standing key={division.name} title={division.name} standingItems={standingsForDivision} /></Card.Body></Card>);
        });

        setStandings(content);
        setContent(content);
    }
    
    return (
        <>
        <SEO 
            title="FantasyBlizzard.com -- Divisional Standings" 
            description="Fantasy Blizzard's divisional standings. Player scores and averages broken into groups" 
            crawl={true} 
        />
            <h1>Divisional Standings</h1>    
            {content}
        </>
        )
}

export default DivisionalStandings;