import React, {useEffect, useState} from 'react';
import {Card, Spinner} from 'react-bootstrap'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function TopLocationsGraph(props) {
  const [data, setData] = useState({});
  const [rawData, setRawData] = useState({});
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    if(props.data == null || props.data.length == 0){
      fetchData();
    }
    else{      
      const data = props.data.sort(function(a, b){

        if(b.score - a.score < 0){
          return -1;
        }
        else if(b.score - a.score > 0){
          return 1;
        }

        if(b.locationIdentifier - a.locationIdentifier < 0){
          return -1;
        }
        else{
          return 1;
        }
      }).slice(0, props.count);

      setupChartDataObject(data);
    }
  }, [props]);

  function fetchData(){
    
    fetch("scores/live/top-location-scores/" + props.count)
      .then((response) => {
          return response.json();
      })
      .then((response) => {
        if(response.success){
          setupChartDataObject(response.data);
        }
        else{
          setupChartDataObject(props.data)
        }
      })
      .catch(() => {
      })
      .finally(() => {
        setLoadingComplete(true);
      });
  }

  const options = {
    plugins: {
      title: {
        display: false,
        text: '',
      },
      tooltip: {
        callbacks: {     
          label: (yDatapoint) => {
            const series = yDatapoint.dataset.label;
            
            if(series === "HDD"){
              return yDatapoint.dataset.label + " " + yDatapoint.raw;
            }
            else if(series === "Wind"){
              return yDatapoint.dataset.label + " " + yDatapoint.raw + "mph";
            }
            else if(series === "Snow" && yDatapoint.raw > 0){
              return yDatapoint.dataset.label + " " + (yDatapoint.raw * .1).toFixed(1) + "\"";
            }
            else{
              return yDatapoint.dataset.label + " " + yDatapoint.raw;
            }
            
          },
          title: (xDatapoint) => {
            const item = rawData.filter(x => x.index == xDatapoint[0].dataIndex);
            
            if(item.length === 1){
              return item[0].locationName;
            }
            else{
              return xDatapoint.raw;
            }
          }
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        title: { display: true, text: 'Points' }
      },
    }
  };
  

  function setupChartDataObject(locationData){
    
    const locations = locationData.map(x => x.locationIdentifier);
    
    const data = {
      labels: locations,
      datasets: [
        {
          label: 'Snow',
          data: locationData.map(x => x.snow * 10),
          backgroundColor: 'rgb(192,192,192)',
        },
        {
          label: 'Wind',
          data: locationData.map(x => x.wind),
          backgroundColor: 'rgb(75, 192, 192)',
        },
        {
          label: 'HDD',
          data: locationData.map(x => x.hdd),
          backgroundColor: 'rgb(0,0,255)',
        },
      ],
    };

    locationData = locationData.map((obj, index) => ({ ...obj, index: index }));

    setRawData(locationData);
    setData(data);
    setLoadingComplete(true);
  }

  return (
    <Card style={{ height: '420px' }}>
      <Card.Header className="bg-dark text-white">Today&apos;s Top {props.count} Location Scores</Card.Header>
      <Card.Body>
        {!loadingComplete && <Spinner className="card-body-loading" animation="border" />}
        {loadingComplete && <Bar options={options} data={data} /> }                 
      </Card.Body>

    </Card>
  );
}
