import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import SEO from '../Components/Seo';
import YesterdayTopLocationScores from '../Components/YesterdayTopLocationScores';

function YesterdayScores (){

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {


        fetch("scores/live/locations")
            .then((response) => {
                return response.json();
            })
            .then(() => {
            })
            .catch(() => {

            })
            .finally(() => {

            });
    }

    return(
        <>
            <SEO 
                title="FantasyBlizzard.com -- Yesterday Location Scores" 
                description="All location scores for yesterday. Each location's snow, cold, and wind scores." 
                crawl={true} 
            />

            <Row>
                <Col sm="12">
                    <h1>Yesterday&apos;s Location Scores</h1>
                    <p>Below are all of the location scores for yesterday. Throughout the morning, scores on this page could change as new data is posted. If scores are showing as 0, it indicates that location&apos;s daily climate report has not yet been published by the National Weather Service.</p>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col sm="12">
                    <Card style={{ minHeight: '100px' }}>
                        <Card.Header className="bg-dark text-white">All Location Scores</Card.Header>
                        <Card.Body>
                            <YesterdayTopLocationScores count={1000} />                        
                        </Card.Body>                  
                    </Card>
                </Col>
            </Row> 
        </>
    );
}

export default YesterdayScores;