import React from 'react';
import { faTrophy, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col } from 'react-bootstrap';

function PlayerAward(props){

    let iconToUse = null;
    let iconCssClass = "";
    
    if(props.awardID != 12){
        iconToUse = faTrophy;
    }
    else{
        iconToUse = faStar;
    }

    if(props.awardID === 1 || props.awardID === 10 || props.awardID === 12){
        iconCssClass = "award-silver";
    }
    else if(props.awardID === 2 || props.awardID === 14){
        iconCssClass = "award-gold";
    }
    else if(props.awardID === 3 || props.awardID === 5 || props.awardID === 6 || props.awardID === 8 || props.awardID === 11 || props.awardID === 12) {
        iconCssClass = "award-bronze";
    }

    return (
        <>
            <Col sm="12">
                <FontAwesomeIcon className={iconCssClass} icon={iconToUse}  /> {props.awardName} -- {props.seasonName}
            </Col>
        </>
    );
}

export default PlayerAward;