import {Button, Col, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate  } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import SEO from '../Components/Seo';

function AdminServices(){

    const [rowsContent, setRowsContent] = useState([]);
    const [loadComplete, setLoadComplete] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [lastUpdated]);

    useEffect(() => {        
        const interval = setInterval(() => { 
            fetchData();
        }, 300000); 
        return () => clearInterval(interval);
    }, []);

    function fetchData(){

        setLoadComplete(false);

        fetch("/adminUtil/services")
            .then((response) => {

                if (response.status !== 200) {
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {         
                loadTable(data);
            })
            .catch(() => {

            })
            .finally(() => {
                setLoadComplete(true);
            });
    }

    function loadTable(data){

        const rows = data.data.map((x, index) => (
            <tr key={index}>
                <td className="text-center align-middle">{x.taskKey}</td>
                <td className="text-center align-middle">{x.status}</td>
                <td className="text-center align-middle">{new Date(x.lastRunStartUtc).toLocaleString()} UTC</td>
                <td className="text-center align-middle">{new Date(x.lastRunEndUtc).toLocaleString()} UTC</td>
                <td className="text-center align-middle">{new Date(x.nextRuntimeUtc).toLocaleString()} UTC</td>
                <td className="text-center align-middle"><Button onClick={() => runNowHandler(x.taskKey)}>Run Asap</Button></td>
            </tr>
        ));

        setRowsContent(rows);
    }

    function runNowHandler(key){
        
        var body = {
            key: key
        }
        fetch("/adminUtil/services/run", {
            method: "POST", 
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        .then((response) => {

            // if(response.status !== 200){
            //     navigate("/login");
            // }  

            return response.json();
        })
        .then(() => {            
            setLastUpdated(new Date());
        })
        .catch(() => {

        })
        .finally(() => {
            
        });
    }

    return (
        <>
            <SEO 
                title="FantasyBlizzard.com -- Backend Services" 
                description="" 
                crawl={false} 
            />
            <Row>
                <Col><h1>Backend Services</h1></Col>
            </Row>

            {!loadComplete && <Row><Col className='text-center'><Spinner className='login-loading' animation="border" /></Col></Row>}
            {loadComplete && <>
            <Row className='mb-3'>
                <Col>
                    <Table striped bordered hover size="sm" responsive>
                        <thead className="table-active">
                            <tr>   
                                <th className="text-center align-middle">Name</th>
                                <th className="text-center align-middle">Status</th>  
                                <th className="text-center align-middle">Last Run Start</th>  
                                <th className="text-center align-middle">Last Run End</th>  
                                <th className="text-center align-middle">Next Run Time</th>  
                                <th className="text-center align-middle"></th>                      
                            </tr>
                        </thead>
                        <tbody>
                            {rowsContent}
                        </tbody>
                    </Table>  
                </Col>
            </Row>   
            </>
            }
        
        </>
    )
}

export default AdminServices;