import React from 'react'
import './TournamentBracketCell.css';

function TournamentBracketCell(props){

    let className = "grid-item tournament-bracket-cell";

    if(props.borderTop === 1){
        className += " tournament-bracket-cell-border-top";
    }

    if(props.borderRight === 1){
        className += " tournament-bracket-cell-border-right";
    }

    if(props.borderBottom === 1){
        className += " tournament-bracket-cell-border-bottom";
    }

    if(props.borderLeft === 1){
        className += " tournament-bracket-cell-border-left";
    }

    if(props.additionalClasses !== ""){
        className += " " + props.additionalClasses;
    }

    return (
        <div className={className} key={"row-" + props.rowNumber + "col-" + props.columnNumber} data-row-number={props.rowNumber} data-column-number={props.columnNumber}>{props.text}</div>
    );

}

export default TournamentBracketCell;