import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export function getStandingsRowClass(row) {

    let cssClass = "small";

    if(!row.isOnPace){
        cssClass += " table-danger";
    }
    else if(row.isOnBubble){
        cssClass += " table-warning";
    }

    return cssClass;
}

export function getFormattedDate(date) {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
}

export function setupDaysOutChartLabels (){        
    return ["1 day out", "2 days out", "3 days out", "4 days out", "5+ days out"];    
}

export function setupDistrubutionChartLabels (picks){
    return [...new Set( picks.map(x => x.locationName)) ];
}

export function setupDistrubutionChartData (picks){

    const distributionLabels = setupDistrubutionChartLabels(picks);
    const distrubutionData = [];

    for(var x = 0; x < distributionLabels.length; x++){
        const locationName = distributionLabels[x];

        const count = picks.filter(x => x.locationName === locationName ).length
        distrubutionData.push(count);
    }
    return distrubutionData;
}

export function setupDaysOutChartData (picks){
   
    const distrubutionData = [0, 0, 0, 0, 0];

    for(var x = 0; x < picks.length; x++){
        const pick = picks[x];

        if (pick.daysOut === 1){
            distrubutionData[0]++;
        }
        else if (pick.daysOut === 2){
            distrubutionData[1]++;
        }
        else if (pick.daysOut === 3){
            distrubutionData[2]++;
        }
        else if (pick.daysOut === 4){
            distrubutionData[3]++;
        }
        else if (pick.daysOut >= 5){
            distrubutionData[4]++;
        }
    }
    return distrubutionData;
}

export function determineCurrentObservationIcon(currentWeatherIcon, observation){

    let icon = icons.faCircleQuestion;

    if(currentWeatherIcon === "sun"){
        icon = icons.faSun;
    }
    else if(currentWeatherIcon === "cloud"){
        icon = icons.faCloud;
    }
    else if(currentWeatherIcon === "cloud-sun"){
        icon = icons.faCloudSun;
    }
    else if(currentWeatherIcon === "cloud-rain"){
        icon = icons.faCloudRain;
    }
    else if(currentWeatherIcon === "snowflake"){
        icon = icons.faSnowflake;
    }
    else if(currentWeatherIcon === "smog"){
        icon = icons.faSmog;
    }
    else if(currentWeatherIcon === "cloud-bolt"){
        icon = icons.faCloudBolt;
    }
    else if(currentWeatherIcon === "cloud-showers-heavy"){
        icon = icons.faCloudShowersHeavy;
    }
    else if(currentWeatherIcon === "moon"){
        icon = icons.faMoon;
    }
    else if(currentWeatherIcon === "cloud-moon"){
        icon = icons.faCloudMoon;
    }
    else if(currentWeatherIcon === "wind"){
        icon = icons.faWind;
    }
    else if(currentWeatherIcon === "icicles"){
        icon = icons.faIcicles;
    }

    return <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay = {(props) => (
            <Tooltip id="button-tooltip" {...props}>
              {observation.length > 0 ? observation : "Current observation is not available"}
            </Tooltip>
        )}
    >
        <FontAwesomeIcon className="current-wx-icon" icon={icon} />
    </OverlayTrigger>; 
}

export function determmineTemperatureColor(temperature){

    let color = "000000";

    if(temperature < -20){
        color = "02004F";
    }
    else if(temperature < -10){
        color = "040269";
    }
    else if(temperature < 0){
        color = "060380";
    }
    else if(temperature < 10){
        color = "070498";
    }
    else if(temperature < 20){
        color = "0805A6";
    }
    else if(temperature < 30){
        color = "0905B9";
    }
    else if(temperature < 40){
        color = "0905CD";
    }
    else if(temperature < 50){
        color = "0B06F0";
    }
    else if(temperature < 60){
        color = "FE5C04";
    }
    else if(temperature < 70){
        color = "E73D04";
    }
    else if(temperature < 80){
        color = "E50707";
    }
    else if(temperature < 90){
        color = "AB0505";
    }
    else{
        color = "800000";
    }

    return "#" + color;
}