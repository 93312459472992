import React from 'react';
import { useState,useEffect } from 'react'
import {Col, Row, Table, OverlayTrigger, Tooltip} from 'react-bootstrap'
import SortableTH from '../Components/SortableTH';
import { Link } from 'react-router-dom';
import { useWindowDimension } from '../Hooks/useWindowDimension';
import { getStandingsRowClass } from '../Helper'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Standing(props){

    const [currentSort, setCurrentSort] = useState({column: "rank", ascending: true});
    const [standingItems, setStandingItems] = useState([])
    const [rowsContent, setRowsContent] = useState([]);
    const [width] = useWindowDimension();

    useEffect(() => {
        sortStandings(props.standingItems, currentSort.column, currentSort.ascending);
    }, [props.standingItems]);
 
    function buildHighLowScore(item, isHigh) {

        let text = "";

        if (isHigh && item.highestStation != null) {

            text = item.highestStationScore.toFixed(2) + " points at " + item.highestStation + " on " + new Date(item.highestStationScoreDate).toLocaleDateString();

        } else if (item.lowestStation) {
            text = item.lowestStationScore.toFixed(2) + " points at " + item.lowestStation + " on " + new Date(item.lowestStationScoreDate).toLocaleDateString();
        }

        return text;
    }
    
    function sortStandings(data, field, sortAcending){
       
        const sortedData = data.sort(function(a, b){
            
            let fieldA = a.score; 
            let fieldB = b.score; 

            if(field === "rank"){
                fieldA = a.rank; 
                fieldB = b.rank; 
            }
            else if(field === "name"){
                fieldA = a.displayName.toUpperCase(); 
                fieldB = b.displayName.toUpperCase(); 
            }
            else if(field === "points"){
                fieldA = a.points; 
                fieldB = b.points; 
            }
            else if(field === "picks"){
                fieldA = a.picks; 
                fieldB = b.picks; 
            }
            else if(field === "average"){
                fieldA = a.average; 
                fieldB = b.average; 
            }
            else if(field === "today"){
                fieldA = a.todayPick != null ? a.todayPick.toUpperCase() : ""; 
                fieldB = b.todayPick != null ? b.todayPick.toUpperCase() : "";  
            }
            else if(field === "highest"){
                fieldA = a.highestStationScore; 
                fieldB = b.highestStationScore; 
            }
            else if(field === "lowest"){
                fieldA = a.lowestStationScore; 
                fieldB = b.lowestStationScore; 
            }

            if(sortAcending){
                if (fieldA < fieldB) {
                    return -1;
                }
                if (fieldA > fieldB) {
                    return 1;
                }
            }
            else{            
                if (fieldA > fieldB) {
                    return -1;
                }
                if (fieldA < fieldB) {
                    return 1;
                }
            }

            // names must be equal
            return 0;
        })

        loadData(sortedData);
        setStandingItems(data);
    }

    function loadData(data){

        let rows = [];

        if (data.length > 0) {
            rows = data.map((x, index) => (<tr key={index} className={getStandingsRowClass(x)}>
                <td className='text-center align-middle'>{x.rank}</td>
                <td className='align-middle pl-3'>
                    <Link className="user-profile-link" to={"/profile/" + x.displayName} title={x.displayName}>{buildDisplayNameCell(x)}</Link>
                </td>
                <td className='text-center align-middle'>{x.points.toFixed(1)}</td>
                <td className='text-center align-middle'>{x.picks}</td>
                <td className='text-center align-middle'>{x.average.toFixed(2)}</td>
                <td className='text-center align-middle'>{x.todayPick != null ? x.todayPick : "" }</td>
                <td className='text-center align-middle'>{buildHighLowScore(x, true)}</td>
                <td className='text-center align-middle'>{buildHighLowScore(x, false)}</td>
            </tr>))
        } else {
            rows = <tr><td colSpan="8">Loading standings. Please wait...</td></tr>
        }

        setRowsContent(rows);
    }

    function sortHandler(field){
        let sortAcending = true;

        if(field === currentSort.column){
            sortAcending = !currentSort.ascending;
        }

        sortStandings(standingItems, field, sortAcending);
        setCurrentSort({column: field, ascending: sortAcending});
    }

    function buildDisplayNameCell(pick){

        let limit = 100;

        if(width < 1000)
        {
            limit = 10;
        }

        return (
            <>
                {pick.displayName.length <= limit ? pick.displayName : pick.displayName.substring(0, limit) + "..."}
            </>
        )
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          On the bubble means the player is one pick away from being under the pace to 30.
        </Tooltip>
      );

    return (
        <>
            <Row>
                <Col sm="12">   
                    <Table striped bordered hover size="sm" responsive>
                        <thead>
                            <tr className='table-secondary'>
                                <SortableTH field="rank" text="#" currentSort={currentSort} sortData={sortHandler} />
                                <SortableTH field="name" text="Name" currentSort={currentSort} sortData={sortHandler} />
                                <SortableTH field="points" text="Points" currentSort={currentSort} sortData={sortHandler} />
                                <SortableTH field="picks" text="Picks" currentSort={currentSort} sortData={sortHandler} />
                                <SortableTH field="average" text="Average" currentSort={currentSort} sortData={sortHandler} />
                                <SortableTH field="today" text="Today's Pick" currentSort={currentSort} sortData={sortHandler} />
                                <SortableTH field="highest" text="Highest Score" currentSort={currentSort} sortData={sortHandler} />
                                <SortableTH field="lowest" text="Lowest Score" currentSort={currentSort} sortData={sortHandler} />                          
                            </tr>
                        </thead>
                        <tbody>
                            {rowsContent}
                        </tbody>
                    </Table>                    
                </Col>
            </Row>
            <Row>
                <Col className="text-right">  

                    <span className="on-bubble"></span><span className="legend-text on-bubble-legend">On the bubble</span>   
                    <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <FontAwesomeIcon className="on-bubble-tooltip" icon={faCircleQuestion}  />
                    </OverlayTrigger>               
                    <span className="under-pace"></span><span className="legend-text">Under pace to 30</span>                    
                </Col>
            </Row>
        </>
    );
}

export default Standing;