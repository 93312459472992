import React from 'react';
import { OverlayTrigger, Tooltip} from 'react-bootstrap';
import { faTrash,  faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function RemovePickButton(props){

    const displayMessage = determineMessage();
    const button = buttonToDisplay();

    function determineMessage () {
        
        let message = "";

        if(props.pick.isLocked){
            message = "This pick is locked and cannot be removed";
        }
        else {
            message = "Click here to remove this pick";
        }

        return message;
    }

    function buttonToDisplay () {
        
        let button = [];

        if(props.pick.isLocked){
            button = <FontAwesomeIcon  icon={faLock}  />;
        }
        else {
            button = <FontAwesomeIcon className='pointer' icon={faTrash} onClick={() => props.handleRemove(props.pick)}  />;
        }

        return button;
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          { displayMessage }
        </Tooltip>
      );

    return (
        <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
        {button}
      </OverlayTrigger>     
    );
}

export default RemovePickButton;