import React, {useEffect, useState} from 'react';
import {Card, Spinner} from 'react-bootstrap'
import {  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {getFormattedDate} from '../Helper'

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function LocationDailyScoresGraph(props){

    const [data, setData] = useState({});
    const [, setRawData] = useState({});
    const [loadingComplete, setLoadingComplete] = useState(false);

    useEffect(() => {        
        fetchData();       
    }, []);

    function fetchData(){
    
        fetch("/scores/location/season/" + props.locationID)
          .then((response) => {
              return response.json();
          })
          .then((response) => {
            if(response.success){
              setupChartDataObject(response.data);
            }            
          })
          .catch(() => {
          })
          .finally(() => {
            setLoadingComplete(true);
          });
      }

      const options = {
        plugins: {
          title: {
            display: false,
            text: '',
          },
          tooltip: {
            callbacks: {     
              label: (yDatapoint) => {
                const series = yDatapoint.dataset.label;
                
                if(series === "HDD"){
                  return yDatapoint.dataset.label + " " + yDatapoint.raw;
                }
                else if(series === "Wind"){
                  return yDatapoint.dataset.label + " " + yDatapoint.raw + "mph";
                }
                else if(series === "Snow" && yDatapoint.raw > 0){
                  return yDatapoint.dataset.label + " " + (yDatapoint.raw * .1).toFixed(1) + "\"";
                }
                else{
                  return yDatapoint.dataset.label + " " + yDatapoint.raw;
                }
                
              },
              title: (xDatapoint) => {
                return xDatapoint.raw;
              }
            }
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            stacked: true,
            barPercentage: 0.5
          },
          y: {
            stacked: true,
            title: { display: true, text: 'Points' },
            barPercentage: 0.5
          },
          xAxes: [{ barPercentage: 0.5 }]
        }
    };  

    function setupChartDataObject(locationData){

        const dates = locationData.map(x => getFormattedDate(new Date(x.date)));
        
        while(dates.length < 15){
          dates.push("");
        }

        const data = {
          labels: dates,
          datasets: [
            {
              label: 'Snow',
              data: locationData.map(x => x.snow * 10),
              backgroundColor: 'rgb(192,192,192)',
              maxBarThickness: 20,
            },
            {
              label: 'Wind',
              data: locationData.map(x => x.wind),
              backgroundColor: 'rgb(75, 192, 192)',           
              maxBarThickness: 20,
            },
            {
              label: 'HDD',
              data: locationData.map(x => x.hdd),
              backgroundColor: 'rgb(0,0,255)', 
              maxBarThickness: 20,
            },
          ],
        };
    
        locationData = locationData.map((obj, index) => ({ ...obj, index: index }));
    
        setRawData(locationData);
        setData(data);
        setLoadingComplete(true);
      }

      return (
        <Card style={{ height: '420px' }}>
          <Card.Header className="bg-dark text-white">Season Scores</Card.Header>
          <Card.Body>
            {!loadingComplete && <Spinner className="card-body-loading" animation="border" />}
            {loadingComplete && <Bar options={options} data={data} /> }                 
          </Card.Body>
        </Card>
      );
}

export default LocationDailyScoresGraph;