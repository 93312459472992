import React from 'react';
import { Link } from 'react-router-dom';

function DateLink(props){    

    function setDate(date){
        localStorage.setItem('scoreboard_date', date);
    }

    return <Link className="table-link" to={"/scoreboard"} onClick={ () => setDate(props.date)}>{props.date}</Link>
}

export default DateLink;