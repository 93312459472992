import React from 'react'
import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import Loading from './Loading'
import LoadingError from './LoadingError'
import { Link } from 'react-router-dom';
import { useWindowDimension } from '../Hooks/useWindowDimension';

function DatePlayerScoresTable(props){

    const [content, setContent] = useState();
    const [width] = useWindowDimension();

    useEffect(() => {

        setContent(<Loading text="Loading scores" />);

        if(props.data !== undefined){
            buildTable(props.data);
        }
        else if(Object.hasOwn(props, "date") && props.date !== undefined) {
          
            fetch("scores/player/" + props.date)
                .then((response) => {

                    if (!response.ok) {
                        setContent(<LoadingError />);
                    }
                    return response.json();
                })
                .then((data) => {
                    buildTable(data);
                })
                .catch(() => {
                    setContent(<LoadingError />);
                })
                .finally(() => {

                });
        }
    }, [props]);  

    const buildTable = function (data) {
        
        let rowsContent = [];

        if (data.length > 0) {

            rowsContent = data.map((x, index) => (
                <tr key={ index }>
                    <td className="pl-3 align-middle">
                        <Link className="user-profile-link" to={"/profile/" + x.displayName} title={x.displayName}>{buildDisplayNameCell(x)}</Link>
                    </td>
                    <td className="text-center align-middle">
                        <Link className="table-link" to={"/location/" + x.stationIdentifier + "/" + x.locationName}>{buildLocationNameCell(x)}</Link>
                    </td>
                    <td className="text-center align-middle">{x.winterRawScore > 0 ? x.snow.toFixed(1) : "-"}</td>
                    <td className="text-center align-middle">{x.winterRawScore > 0 ? x.wind : "-"}</td>
                    <td className="text-center align-middle">{x.winterRawScore > 0 ? x.hdd : "-"}</td>
                    <td className="text-center align-middle">{x.winterRawScore > 0 ? x.daysOut : "-"}</td>
                    <td className="text-center align-middle">{x.winterRawScore > 0 ? x.winterRawScore : "-"}</td>
                    <td className="text-center align-middle">{x.winterRawScore > 0 ? x.winterScore.toFixed(1) : "-"}</td>
                </tr>
            ));

            const fullTable = [
                <>
                    <Table key="1" striped bordered hover size="sm" responsive>
                        <thead>
                            <tr className='table-secondary'>
                                <th className="pl-3">Name</th>
                                <th className="text-center align-middle">Location</th>
                                <th className="text-center align-middle">Snow</th>
                                <th className="text-center align-middle">Wind</th>
                                <th className="text-center align-middle">HDD</th>
                                <th className="text-center align-middle">Days Out</th>
                                <th className="text-center align-middle">Raw Score</th>
                                <th className="text-center align-middle">Score</th>                        
                            </tr>
                        </thead>
                        <tbody>
                            {rowsContent}
                        </tbody>
                    </Table>
                    <div className="text-right"><small>If a score is showing as -, it&apos;s likely one or more score components is missing.</small></div>
                </>
                
            ];

            setContent(fullTable);

            if(props.setPicksTotal !== undefined){
                props.setPicksTotal(data.length);
            }
        }
        else {
            setContent(<span className="no-records-text">There were no picks for {props.text}.</span>);
        }   
    }

    function buildDisplayNameCell(pick){

        let limit = 100;

        if(width < 1000)
        {
            limit = 15;
        }
        else if(width < 750)
        {
            limit = 10;
        }

        return (
            <>
                {pick.displayName.length <= limit ? pick.displayName : pick.displayName.substring(0, limit) + "..."}
            </>
        )
    }

    function buildLocationNameCell(pick){

        let limit = 100;

        if(width < 800)
        {
            limit = 10;
        }

        return (
            <>
                {pick.locationName.length <= limit ? pick.locationName : pick.locationName.substring(0, limit) + "..."}
            </>
        )
    }

    return (content);
}

export default DatePlayerScoresTable;