import React from 'react'
import './TournamentBracket16.css';
import TournamentBracketCell from './TournamentBracketCell';

function TournamentBracket(props){

    const matches = [];
    const matchupsArray = [];

    for(let roundIndex = 0; roundIndex < props.data.rounds.length; roundIndex++)
    {
        for(let matchupIndex = 0; matchupIndex < props.data.rounds[roundIndex].matchups.length; matchupIndex++ ){
            matchupsArray.push(props.data.rounds[roundIndex].matchups[matchupIndex]);
        }   
    }

    for(let number = 1; number <= 31; number++){
        matches.push(number);
    }

    function GetMatchupName(gameNumber, isAway, defaultName){

        if(gameNumber - 1 <= matchupsArray.length + 1){

            let displayName = defaultName;
            let seedNumber = 0;

            try{
                if(isAway){
                    displayName = matchupsArray[gameNumber - 1].awayDisplayName;
                    seedNumber = matchupsArray[gameNumber - 1].awaySeed;
                }
                else{
                    displayName = matchupsArray[gameNumber - 1].homeDisplayName;
                    seedNumber = matchupsArray[gameNumber - 1].homeSeed;
                }

                if(displayName.length > 10){
                    displayName = displayName.substring(0, 10) + "...";
                }
                
                if(gameNumber <= 16){
                    displayName = seedNumber + ". " + displayName;
                }
            }
            catch(error){
                console.log("gameNumber", gameNumber);  
                displayName = defaultName;              
            }
            return displayName;
        }
        else{
            return defaultName;
        }
    }

    return (
        <div className="grid-container">
            <div className="grid-item tournament-bracket-round-name">Synoptic 16</div>
            <div className="grid-item tournament-bracket-round-name">Adiabatic 8</div>  
            <div className="grid-item tournament-bracket-round-name">Frontogenetical 4</div>
            <div className="grid-item tournament-bracket-round-name grid-container-span-2">Thermodynamic 2</div>
            <div className="grid-item tournament-bracket-round-name">Frontogenetical 4</div>  
            <div className="grid-item tournament-bracket-round-name">Adiabatic 8</div>
            <div className="grid-item tournament-bracket-round-name">Synoptic 16</div>        

            <div className="grid-item tournament-bracket-round-date">3/1 to 3/5</div>
            <div className="grid-item tournament-bracket-round-date">3/6 to 3/10</div>  
            <div className="grid-item tournament-bracket-round-date">3/11 to 3/15</div>
            <div className="grid-item tournament-bracket-round-date grid-container-span-2">3/16 to 3/20</div>
            <div className="grid-item tournament-bracket-round-date">3/11 to 3/15</div>  
            <div className="grid-item tournament-bracket-round-date">3/6 to 3/10</div>
            <div className="grid-item tournament-bracket-round-date">3/1 to 3/5</div>     

            {/* Row 1 */}

            {/* Row 2 */}
            <TournamentBracketCell rowNumber={2} columnNumber={2} text={GetMatchupName(1, true, "#1")} borderBottom={1} />
            <TournamentBracketCell rowNumber={2} columnNumber={3} text="" />
            <TournamentBracketCell rowNumber={2} columnNumber={4} text="" />
            <TournamentBracketCell rowNumber={2} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={2} columnNumber={6} text="" />
            <TournamentBracketCell rowNumber={2} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={2} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={2} columnNumber={9} text={GetMatchupName(5, true, "#2")} borderBottom={1} />

            {/* Row 3 */}
            <TournamentBracketCell rowNumber={3} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={3} columnNumber={3} text="" />
            <TournamentBracketCell rowNumber={3} columnNumber={4} text="" />
            <TournamentBracketCell rowNumber={3} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={3} columnNumber={6} text="" />
            <TournamentBracketCell rowNumber={3} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={3} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={3} columnNumber={9} text="" />

            {/* Row 4 */}
            <TournamentBracketCell rowNumber={4} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={4} columnNumber={3} text={GetMatchupName(9, true, "")} borderBottom={1} />
            <TournamentBracketCell rowNumber={4} columnNumber={4} text="" />
            <TournamentBracketCell rowNumber={4} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={4} columnNumber={6} text="" />
            <TournamentBracketCell rowNumber={4} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={4} columnNumber={8} text={GetMatchupName(11, true, "")} borderBottom={1} borderRight={1} />
            <TournamentBracketCell rowNumber={4} columnNumber={9} text="" />

            {/* Row 5 */}
            <TournamentBracketCell rowNumber={5} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={5} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={5} columnNumber={4} text="" />
            <TournamentBracketCell rowNumber={5} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={5} columnNumber={6} text="" />
            <TournamentBracketCell rowNumber={5} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={5} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={5} columnNumber={9} text="" />

            {/* Row 6 */}
            <TournamentBracketCell rowNumber={6} columnNumber={2} text={GetMatchupName(1, false, "#16", "")} borderBottom={1}  borderRight={1}/>
            <TournamentBracketCell rowNumber={6} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={6} columnNumber={4} text="" />
            <TournamentBracketCell rowNumber={6} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={6} columnNumber={6} text="" />
            <TournamentBracketCell rowNumber={6} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={6} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={6} columnNumber={9} text={GetMatchupName(5, false, "#15", "")} borderBottom={1} />

            {/* Row 7 */}
            <TournamentBracketCell rowNumber={7} columnNumber={2} text="" />
            <TournamentBracketCell rowNumber={7} columnNumber={3} text=""  borderRight={1} />
            <TournamentBracketCell rowNumber={7} columnNumber={4} text="" />
            <TournamentBracketCell rowNumber={7} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={7} columnNumber={6} text="" />
            <TournamentBracketCell rowNumber={7} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={7} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={7} columnNumber={9} text="" />

            {/* Row 8 */}
            <TournamentBracketCell rowNumber={8} columnNumber={2} text="" />
            <TournamentBracketCell rowNumber={8} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={8} columnNumber={4} text={GetMatchupName(13, true, "")} borderBottom={1} />
            <TournamentBracketCell rowNumber={8} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={8} columnNumber={6} text="" />
            <TournamentBracketCell rowNumber={8} columnNumber={7} text={GetMatchupName(14, true, "")} borderRight={1} borderBottom={1} />
            <TournamentBracketCell rowNumber={8} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={8} columnNumber={9} text="" />

            {/* Row 9 */}
            <TournamentBracketCell rowNumber={9} columnNumber={2} text="" />
            <TournamentBracketCell rowNumber={9} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={9} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={9} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={9} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={9} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={9} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={9} columnNumber={9} text="" />

            {/* Row 10 */}
            <TournamentBracketCell rowNumber={10} columnNumber={2} text={GetMatchupName(2, true, "#8")} borderBottom={1} />
            <TournamentBracketCell rowNumber={10} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={10} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={10} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={10} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={10} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={10} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={10} columnNumber={9} text={GetMatchupName(6, true, "#7")} borderBottom={1} />

            {/* Row 11 */}
            <TournamentBracketCell rowNumber={11} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={11} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={11} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={11} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={11} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={11} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={11} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={11} columnNumber={9} text="" />

            {/* Row 12 */}
            <TournamentBracketCell rowNumber={12} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={12} columnNumber={3} text={GetMatchupName(9, false, "")} borderRight={1} borderBottom={1} />
            <TournamentBracketCell rowNumber={12} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={12} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={12} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={12} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={12} columnNumber={8} text={GetMatchupName(11, false, "")} borderRight={1} borderBottom={1} />
            <TournamentBracketCell rowNumber={12} columnNumber={9} text="" />

            {/* Row 13 */}
            <TournamentBracketCell rowNumber={13} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={13} columnNumber={3} text="" />
            <TournamentBracketCell rowNumber={13} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={13} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={13} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={13} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={13} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={13} columnNumber={9} text="" />

            {/* Row 14 */}
            <TournamentBracketCell rowNumber={14} columnNumber={2} text={GetMatchupName(2, false, "#9")} borderBottom={1} borderRight={1} />
            <TournamentBracketCell rowNumber={14} columnNumber={3} text="" />
            <TournamentBracketCell rowNumber={14} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={14} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={14} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={14} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={14} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={14} columnNumber={9} text={GetMatchupName(6, false, "#10")} borderBottom={1} />

            {/* Row 15 */}
            <TournamentBracketCell rowNumber={15} columnNumber={2} text="" />
            <TournamentBracketCell rowNumber={15} columnNumber={3} text="" />
            <TournamentBracketCell rowNumber={15} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={15} columnNumber={5} text={GetMatchupName(15, true, "")} borderBottom={1} />
            <TournamentBracketCell rowNumber={15} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={15} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={15} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={15} columnNumber={9} text="" />

            {/* Row 16 */}
            <TournamentBracketCell rowNumber={16} columnNumber={2} text="" />
            <TournamentBracketCell rowNumber={16} columnNumber={3} text="" />
            <TournamentBracketCell rowNumber={16} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={16} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={16} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={16} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={16} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={16} columnNumber={9} text="" />

            {/* Row 17 */}
            <TournamentBracketCell rowNumber={17} columnNumber={2} text="" />
            <TournamentBracketCell rowNumber={17} columnNumber={3} text="" />
            <TournamentBracketCell rowNumber={17} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={17} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={17} columnNumber={6} text={GetMatchupName(15, false, "")}borderRight={1}  borderBottom={1} />
            <TournamentBracketCell rowNumber={17} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={17} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={17} columnNumber={9} text="" />

            {/* Row 18 */}
            <TournamentBracketCell rowNumber={18} columnNumber={2} text={GetMatchupName(3, true, "#4")} borderBottom={1} />
            <TournamentBracketCell rowNumber={18} columnNumber={3} text="" />
            <TournamentBracketCell rowNumber={18} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={18} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={18} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={18} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={18} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={18} columnNumber={9} text={GetMatchupName(7, true, "#3")} borderBottom={1} />

            {/* Row 19 */}
            <TournamentBracketCell rowNumber={19} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={19} columnNumber={3} text="" />
            <TournamentBracketCell rowNumber={19} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={19} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={19} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={19} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={19} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={19} columnNumber={9} text="" />

            {/* Row 20 */}
            <TournamentBracketCell rowNumber={20} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={20} columnNumber={3} text={GetMatchupName(10, true, "")} borderBottom={1} />
            <TournamentBracketCell rowNumber={20} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={20} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={20} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={20} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={20} columnNumber={8} text={GetMatchupName(12, true, "")} borderRight={1} borderBottom={1} />
            <TournamentBracketCell rowNumber={20} columnNumber={9} text="" />

            {/* Row 21 */}
            <TournamentBracketCell rowNumber={21} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={21} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={21} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={21} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={21} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={21} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={21} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={21} columnNumber={9} text="" />

            {/* Row 22 */}
            <TournamentBracketCell rowNumber={22} columnNumber={2} text={GetMatchupName(3, false, "#13")} borderBottom={1} borderRight={1} />
            <TournamentBracketCell rowNumber={22} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={22} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={22} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={22} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={22} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={22} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={22} columnNumber={9} text={GetMatchupName(7, false, "#14")} borderBottom={1} />

            {/* Row 23 */}
            <TournamentBracketCell rowNumber={23} columnNumber={2} text="" />
            <TournamentBracketCell rowNumber={23} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={23} columnNumber={4} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={23} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={23} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={23} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={23} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={23} columnNumber={9} text="" />

            {/* Row 24 */}
            <TournamentBracketCell rowNumber={24} columnNumber={2} text="" />
            <TournamentBracketCell rowNumber={24} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={24} columnNumber={4} text={GetMatchupName(13, false, "")} borderRight={1} borderBottom={1} />
            <TournamentBracketCell rowNumber={24} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={24} columnNumber={6} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={24} columnNumber={7} text={GetMatchupName(14, false, "")} borderRight={1} borderBottom={1} />
            <TournamentBracketCell rowNumber={24} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={24} columnNumber={9} text="" />

            {/* Row 25 */}
            <TournamentBracketCell rowNumber={25} columnNumber={2} text="" />
            <TournamentBracketCell rowNumber={25} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={25} columnNumber={4} text="" />
            <TournamentBracketCell rowNumber={25} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={25} columnNumber={6} text="" />
            <TournamentBracketCell rowNumber={25} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={25} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={25} columnNumber={9} text="" />

            {/* Row 26 */}
            <TournamentBracketCell rowNumber={26} columnNumber={2} text={GetMatchupName(4, true, "#5")} borderBottom={1} />
            <TournamentBracketCell rowNumber={26} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={26} columnNumber={4} text="" />
            <TournamentBracketCell rowNumber={26} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={26} columnNumber={6} text="" />
            <TournamentBracketCell rowNumber={26} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={26} columnNumber={8} text="" />
            <TournamentBracketCell rowNumber={26} columnNumber={9} text={GetMatchupName(8, true, "#6")} borderBottom={1} />

            {/* Row 27 */}
            <TournamentBracketCell rowNumber={27} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={27} columnNumber={3} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={27} columnNumber={4} text="" />
            <TournamentBracketCell rowNumber={27} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={27} columnNumber={6} text="" />
            <TournamentBracketCell rowNumber={27} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={27} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={27} columnNumber={9} text="" />

            {/* Row 28 */}
            <TournamentBracketCell rowNumber={28} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={28} columnNumber={3} text={GetMatchupName(10, false, "")}borderRight={1} borderBottom={1} />
            <TournamentBracketCell rowNumber={28} columnNumber={4} text="" />
            <TournamentBracketCell rowNumber={28} columnNumber={5} text="" />
            <TournamentBracketCell rowNumber={28} columnNumber={6} text="" />
            <TournamentBracketCell rowNumber={28} columnNumber={7} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={28} columnNumber={8} text={GetMatchupName(12, false, "")} borderRight={1} borderBottom={1} />
            <TournamentBracketCell rowNumber={28} columnNumber={9} text="" />

            {/* Row 29 */}
            <TournamentBracketCell rowNumber={29} columnNumber={2} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={29} columnNumber={3} text="" />
            <TournamentBracketCell rowNumber={29} columnNumber={4} text="" />
            <TournamentBracketCell additionalClasses="grid-container-span-2" rowNumber={13} columnNumber={5} text={props.data.winnerName} borderBottom={1} />
            <TournamentBracketCell rowNumber={29} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={29} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={29} columnNumber={9} text=""  />

            {/* Row 30 */}
            <TournamentBracketCell rowNumber={30} columnNumber={2} text={GetMatchupName(4, false, "#12")} borderBottom={1} borderRight={1} />
            <TournamentBracketCell rowNumber={30} columnNumber={3} text="" />
            <TournamentBracketCell rowNumber={30} columnNumber={4} text="" />
            <TournamentBracketCell additionalClasses="grid-container-span-2 font-weight-bold" rowNumber={30} columnNumber={5} text={"Winner"} />
            <TournamentBracketCell rowNumber={30} columnNumber={7} text="" />
            <TournamentBracketCell rowNumber={30} columnNumber={8} text="" borderRight={1} />
            <TournamentBracketCell rowNumber={30} columnNumber={9} text={GetMatchupName(8, false, "#11")} borderBottom={1} />

            {/* Row 31 */}

        </div>   
    );
}

export default TournamentBracket;