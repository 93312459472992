import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, description, crawl}) {
return (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        {crawl ? <meta name="robots" content="index, follow" /> : <meta name="robots" content="noindex, nofollow" />}
    </Helmet>
    )
}