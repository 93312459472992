import React from "react";
import { GoogleMap, Marker,  LoadScript } from "@react-google-maps/api";

const containerStyle = {width: "100%",height: "100%"};

function LocationMap(props) {

    const center = { lat: props.location.latitude, lng: props.location.longitude };
 
    return (
        <LoadScript googleMapsApiKey="AIzaSyBP_J5w_dEq0pxQTJuI0apXs9d2v8S6rvk">
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={7}>
                <Marker
                    key="1"
                    position={center} 
                />
            </GoogleMap>
        </LoadScript>
    );
}

export default LocationMap;
